export const FILE_PATTERN_KEY = 'RIVER_ATTACHED_FILE';
export const FILE_PATTERN_KEY_REGEX = /<RIVER_ATTACHED_FILE:(.*?)>/;

export const getFileExtension = (filename: string) =>
  filename.split('.').pop()?.toLocaleLowerCase() || '';

export const getFileType = (filenameOrUrl: string): 'image' | 'document' | 'unknown' => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'];

  const extension = filenameOrUrl.split('.').pop()?.toLowerCase() || '';

  if (imageExtensions.includes(extension)) {
    return 'image';
  }

  if (documentExtensions.includes(extension)) {
    return 'document';
  }

  return 'unknown';
};

export const getFileNameFromURL = (url: string) => {
  if (!url) return url;

  const { pathname } = new URL(url);

  const lastSlashIndex = pathname.lastIndexOf('/');

  const fileNameWithExtension = pathname.substring(lastSlashIndex + 1);

  const lastDotIndex = fileNameWithExtension.lastIndexOf('.');

  const extension = fileNameWithExtension.substring(lastDotIndex + 1);

  const fileName = fileNameWithExtension.substring(0, lastDotIndex);

  return `${fileName}.${extension}`;
};
