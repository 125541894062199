import { Storage } from 'aws-amplify';
import env from './env';
import { reportErrorToSentry } from './reportToSentry';

const uploadAWSS3File = async (
  file: any,
  fileKey: string,
  fileType: string,
  level: string,
): Promise<any> => {
  try {
    const result: any = await Storage.put(fileKey, file, {
      contentType: fileType,
    });
    return `https://${env('RIVER_AWS_BUCKET_NAME')}/${level}/${result?.key}`;
  } catch (error: any) {
    reportErrorToSentry(error, {
      clientAction: 'User tried uploading an image. Error in *uploadAWSS3File.ts*',
    });

    return undefined;
  }
};

export default uploadAWSS3File;
