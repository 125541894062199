import * as React from 'react';
import SimpleReactValidator from 'simple-react-validator';

type ValidatorRule = {
  message: string;
  rule: (val: string, params?: Array<string>, validator?: any) => boolean;
};

const useValidator = (
  customMessage: Record<string, unknown> = {},
  customValidator: Record<string, ValidatorRule> = {},
) => {
  const [show, setShow] = React.useState(false);

  const validator = new SimpleReactValidator({
    messages: customMessage,
    validators: customValidator,
  });

  if (show) {
    validator.showMessages();
  }

  return [validator, setShow] as any;
};

export default useValidator;
