import * as React from 'react';
import { Button, Icon, Modal } from '../..';

type ModalProps = {
  status: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
  onCloseModal?: () => void;
};

function NonEmployeePrompt({ status, onCloseModal }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[450px]">
        <div className="flex flex-col px-7 py-7 pb-6 items-center text-center">
          <div className="w-[80px] h-[80px] rounded-full bg-[#FFE5E0] flex flex-col items-center justify-center">
            <Icon id="iconAlertDanger" className="w-10 h-auto object-center object-cover" />
          </div>

          <p className="text-river-black text-sm font-normal mt-3">
            Your email or phone number is not connected to an employer on River Health. Make sure
            you are signing up with the email and phone number you provided to your employer.
          </p>

          {status === 'LOADING' ? (
            <div className="mt-12 tablet:mt-6 flex flex-col items-center">
              <span className="inline-flex items-center">
                Please wait... <Icon id="loader" className="w-5 h-5 ml-4" />
              </span>
            </div>
          ) : (
            <div className="mt-12 tablet:mt-6 flex flex-col items-center">
              <Button label="Try again" onClick={() => onCloseModal?.()} />
            </div>
          )}

          <p className="text-river-deep-orange text-xs mt-4">
            Need help? Call or text our support team at{' '}
            <a href="tel:(888) 814-6062" className="inline-flex">
              (888) 814-6062
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
}

NonEmployeePrompt.defaultProps = {
  onCloseModal: undefined,
};

export default NonEmployeePrompt;
