import React, { ChangeEvent, FocusEvent } from 'react';

type TextBoxProps = {
  className?: string;
  name: string;
  id?: string;
  rows?: number;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
};

function TextBoxInput({
  className,
  rows,
  name,
  id,
  disabled,
  error,
  onBlur,
  onFocus,
  placeholder,
  value,
  onChange,
}: TextBoxProps) {
  return (
    <textarea
      className={`${className} ${error ? 'border-river-red' : ''}`}
      name={name}
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      rows={rows}
    />
  );
}

TextBoxInput.defaultProps = {
  className: '',
  id: '',
  rows: 4,
  placeholder: '',
  disabled: false,
  error: false,
  onBlur: undefined,
  onFocus: undefined,
};

export default TextBoxInput;
