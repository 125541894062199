import * as React from 'react';
import { Button, Icon } from '..';
import calcReadableFileSize from '../../utils/calcReadableFileSize';

type FilePreviewerProps = {
  file: File;
  onCloseModal: () => void;
  onSendMessage: () => void;
};

function ChatFilePreviewer({ file, onCloseModal, onSendMessage }: FilePreviewerProps) {
  const fileURL = URL.createObjectURL(file);

  return (
    <div className="absolute top-0 bg-[#f5f5f5] left-0 right-0 bottom-0 z-50 transform translate-y-0 overflow-hidden">
      <div className="w-full h-full text-center p-5 overflow-y-scroll flex flex-col justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="w-16 hidden tablet:block" />
          <span>{file.name}</span>
          <button type="button" className="w-16 cursor-pointer ml-2" onClick={onCloseModal}>
            <Icon id="close" className="w-[18px] inline-flex" />
          </button>
        </div>

        <div className="w-full mt-5 tablet:mt-8">
          <div className="flex items-center justify-center overflow-hidden w-[300px] mx-auto">
            {file.type.includes('image') ? (
              <img
                src={fileURL}
                alt="attachment preview"
                className="object-center object-cover w-full h-full"
              />
            ) : (
              <div className="flex flex-col items-center">
                <Icon id="iconChatDocumentUpload" className="mb-5" />
                <p className="mb-[5px] font-medium">No preview available</p>
                <span className="text-xs">
                  {calcReadableFileSize(file.size)} -{' '}
                  {file.name.split('.').pop()?.toLocaleUpperCase()}
                </span>
              </div>
            )}
          </div>
          <div className="relative w-full min-h-[56px] mt-10 tablet:mt-12">
            <Button label="Send" onClick={onSendMessage} className="" />
          </div>
        </div>
      </div>
    </div>
  );
}

ChatFilePreviewer.defaultProps = {};

export default ChatFilePreviewer;
