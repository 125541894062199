import * as React from 'react';
import { Icon, Modal } from '../..';

type PhotoPreviewerProps = {
  url: string;
  onCloseModal: () => void;
};

function VideoViewer({ url, onCloseModal }: PhotoPreviewerProps) {
  const [status, setStatus] = React.useState<string>('LOADING');

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[800px]">
        <div className="relative flex flex-col px-5 py-6 h-[450px] justify-center items-center">
          {status === 'LOADING' && (
            <span className="absolute left-[50%] translate-x-[-50%]">
              <Icon id="loader" className="w-14 h-14" />
            </span>
          )}

          <iframe
            title="vimeo-player"
            src={url}
            width="100%"
            height="450"
            frameBorder="0"
            onLoad={() => {
              setStatus('IDLE');
            }}
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
}

export default VideoViewer;
