const capitalize = (value: string) => {
  return value
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
};
export default capitalize;
