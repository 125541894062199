export const PROFILE_PHOTO_FOLDER = 'avatar';

export const CHAT_FILE_FOLDER = 'chat';

export const CHAT_LIMIT = 30;

export const VISIT_STATUS = {
  COMPLETE: 'COMPLETED',
  PENDING: 'PENDING',
  UNANSWERED: 'IN-PROGRESS',
};
