import * as React from 'react';
import { Button, Graphic, Modal } from '../..';

type ModalProps = {
  onConfirmBooking: () => void;
};

function AppointmentInfoPrompt({ onConfirmBooking }: ModalProps) {
  return (
    <Modal show>
      <div className="rounded-xl bg-white tablet:w-[500px]">
        <div className="flex flex-col px-7 py-7 pb-10 items-center text-center">
          <div className="w-[180px] h-[180px] rounded-full bg-[#FFF8ED] flex flex-col items-center justify-center">
            <Graphic
              id="graphicApptInfoCompletePrompt"
              className="w-[150px] h-auto object-center object-cover"
            />
          </div>

          <p className="text-river-black text-sm font-normal mt-3">
            Thanks for booking an appointment with River Health. Contact our support team or your
            primary care provider if you have questions.
          </p>

          <div className="mt-3 flex flex-col items-center">
            <h5
              className="text-river-deep-orange text-sm font-medium mb-1 
            inline-flex whitespace-pre items-center"
            >
              Live Well!
            </h5>
          </div>

          <div className="mt-12 tablet:mt-6">
            <Button label="Return home" onClick={onConfirmBooking} className="" />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AppointmentInfoPrompt;
