/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { reject } from 'ramda';
import { CheckBox } from '../..';
import { Question } from '../../../context/visitContext';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CheckBoxInputBuilderProps = {
  id: string;
  label: string;
  value: string[];
  error?: string;
  notice?: string;
  inputs: Array<{
    id: string;
    priority: number;
    text: string;
    followups?: Question[];
  }>;
  onInputChange: (answers: Answer) => void;
};

function CheckBoxInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  inputs,
  onInputChange,
}: CheckBoxInputBuilderProps) {
  const [checkedItems, setCheckedItems] = React.useState<string[]>(value);

  const checkBoxNone = `${id}_NONE`;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, hasFollowups: boolean) => {
    const { value, checked } = event.target;

    if (checked) {
      if (['NONE', 'SOMETHING_ELSE', 'OTHER'].includes(value) || hasFollowups) {
        setCheckedItems([value]);
        onInputChange({ questionKey: id, answers: [value] });
        return;
      }

      if (
        checkedItems.includes('NONE') ||
        checkedItems.includes('OTHER') ||
        checkedItems.includes('SOMETHING_ELSE')
      ) {
        const removedCheckedItems = reject(
          (checkedItem: string) => ['NONE', 'SOMETHING_ELSE', 'OTHER'].includes(checkedItem),
          checkedItems,
        );
        setCheckedItems([...removedCheckedItems, value].filter(item => item !== '-'));
        onInputChange({
          questionKey: id,
          answers: [...removedCheckedItems, value].filter(item => item !== '-'),
        });
        return;
      }

      setCheckedItems([...checkedItems, value].filter(item => item !== '-'));
      onInputChange({
        questionKey: id,
        answers: [...checkedItems, value].filter(item => item !== '-'),
      });
    } else {
      const removedCheckedItems = reject(checkedItem => checkedItem === value, checkedItems);

      setCheckedItems(removedCheckedItems.filter(item => item !== '-'));
      onInputChange({
        questionKey: id,
        answers: removedCheckedItems.filter(item => item !== '-'),
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>
      {/* tablet:grid-cols-2 */}
      <div className="grid grid-cols-1 gap-5">
        {inputs &&
          inputs.map(input => (
            <label
              key={input.id === 'NONE' ? checkBoxNone : input.id}
              htmlFor={input.id === 'NONE' ? checkBoxNone : input.id}
              className="text-sm block pl-[18px] -indent-[18px] cursor-pointer"
            >
              <CheckBox
                id={input.id === 'NONE' ? checkBoxNone : input.id}
                name={input.id === 'NONE' ? checkBoxNone : input.id}
                value={input.id}
                checked={checkedItems.includes(input.id)}
                onChange={event => handleInputChange(event, Boolean(input?.followups?.length))}
                className="align-middle cursor-pointer"
              />{' '}
              <span className="align-middle">{input.text}</span>
            </label>
          ))}
      </div>
      {error && <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

CheckBoxInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CheckBoxInputBuilder;
