import * as React from 'react';

type FetchState = {
  data: unknown;
  status: string;
  error: unknown;
};

type FetchAction = {
  type: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
  data: unknown;
  error: unknown;
};

function fetchReducer(state: FetchState, action: FetchAction): FetchState {
  switch (action.type) {
    case 'IDLE':
      return { ...state, status: 'IDLE' };
    case 'LOADING':
      return { ...state, status: 'LOADING' };
    case 'RESOLVED':
      return { ...state, status: 'RESOLVED', data: action.data };
    case 'REJECTED':
      return { ...state, status: 'REJECTED', error: action.error };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function useFetchAsync() {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    data: null,
    status: 'IDLE',
    error: null,
  });

  const run = React.useCallback((promise: Promise<unknown>) => {
    if (!promise) {
      return;
    }

    dispatch({ type: 'LOADING', data: null, error: null });

    promise.then(
      (response: unknown) => {
        dispatch({ type: 'RESOLVED', data: response, error: null });
      },
      (error: unknown) => {
        dispatch({ type: 'REJECTED', data: null, error });
      },
    );
  }, []);

  return { ...state, run };
}

export default useFetchAsync;
