import React from 'react';
import Croppie from 'croppie';
import 'croppie/croppie.css';
import { Button, Modal } from '../..';

const CROPPIE_OPTIONS = {
  enableExif: true,
  viewport: {
    width: 200,
    height: 200,
    type: 'square',
  },
  boundary: {
    width: 350,
    height: 350,
  },
};

type PhotoCropperProps = {
  photo?: File;
  onCloseModal: () => void;
  onSetCroppedPhoto: (blob: Blob) => void;
};

function PhotoCropper({ photo, onCloseModal, onSetCroppedPhoto }: PhotoCropperProps) {
  const croppie = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (document.getElementById('photo-previewer')) {
      croppie.current = new Croppie(document.getElementById('photo-previewer'), CROPPIE_OPTIONS);
    }
  }, []);

  React.useEffect(() => {
    if (!photo) return;
    (async function readPhotoDataURLAndBindToCroppie() {
      const fileURL = URL.createObjectURL(photo);

      const croppieElement = croppie.current as any;

      croppieElement.bind({
        url: fileURL,
      });
    })();
  }, [photo]);

  const handleCroppedPhoto = () => {
    const croppieElement = croppie.current as any;

    croppieElement
      .result({
        type: 'blob',
        size: {
          width: 400,
          height: 400,
        },
      })
      .then((blob: Blob) => {
        onSetCroppedPhoto(blob);
        onCloseModal();
      });
  };

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[650px]">
        <div className="flex flex-col p-8 pt-8 pb-10">
          <h3 className="text-river-black text-[20px] font-semibold">Edit photo</h3>

          <div
            id="photo-previewer"
            className="croppie relative flex flex-col items-center 
            justify-center mt-[30px] mb-[20px] overflow-hidden"
          />

          <div className="text-center w-full flex flex-col items-center">
            <Button label="Apply" onClick={handleCroppedPhoto} className="!px-4" />
          </div>
        </div>
      </div>
    </Modal>
  );
}

PhotoCropper.defaultProps = {
  photo: undefined,
};

export default PhotoCropper;
