import * as React from 'react';

type TextReaderProps = {
  lessTextLabel?: string;
  moreTextLabel?: string;
  text: string;
  textLinesLimit?: number;
  className?: string;
};

function ExpandableTextReader({
  text,
  lessTextLabel,
  moreTextLabel,
  textLinesLimit,
  className,
}: TextReaderProps) {
  const [expandText, setExpandText] = React.useState(false);
  const [showExpandableAction, setShowExpandableAction] = React.useState(false);
  const textElementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!textElementRef.current) {
      return;
    }

    const divHeight = textElementRef.current.scrollHeight;

    const lineHeight = parseInt(
      window.getComputedStyle(textElementRef.current).getPropertyValue('line-height'),
      10,
    );

    const lines = divHeight / lineHeight;

    const textLimit = textLinesLimit || 0;

    setShowExpandableAction(lines > textLimit);
  }, [textLinesLimit]);

  return (
    <div>
      <div
        ref={textElementRef}
        style={
          !expandText
            ? {
                wordBreak: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: textLinesLimit,
                WebkitBoxOrient: 'vertical',
              }
            : {}
        }
        className={className || ''}
      >
        {text}
      </div>

      {showExpandableAction && (
        <button
          type="button"
          className="text-river-blue cursor-pointer"
          onClick={() => setExpandText(!expandText)}
        >
          {expandText ? `${lessTextLabel}` : `${moreTextLabel}`}
        </button>
      )}
    </div>
  );
}

ExpandableTextReader.defaultProps = {
  moreTextLabel: 'Read more',
  lessTextLabel: 'Read less',
  className: '',
  textLinesLimit: 2,
};

export default ExpandableTextReader;
