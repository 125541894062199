import React from 'react';
import { Modal } from '../..';
import { ReactComponent as RiverLogo } from '../../../assets/images/image_river_logo.svg';
import { AuthUser } from '../../../context/authContext';

type ModalProps = {
  user: AuthUser;
  showModal: boolean;
  onCloseModal: () => void;
};

function PrescriptionCard({ user, onCloseModal, showModal }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show={showModal}>
      <div className="rounded-[18px] tablet:w-[480px] bg-white shadow-xl">
        <div className="relative flex flex-col px-8 pt-5 pb-7">
          <RiverLogo className="w-[75px] mb-4" />

          <div className="space-y-[6px] text-[15px]">
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">Patient Name</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1">
                {`${user?.firstName} ${user?.lastName}`}
              </div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">Patient ID</div>
              <div
                className={`col-span-2 text-sm font-ptSans font-bold mobile:-mt-1 ${
                  user?.insurance?.subscriberId ? '' : 'text-[#e96262]'
                }`}
              >
                {user?.insurance?.subscriberId || 'Will be available in 5 days'}
              </div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">BIN</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1">610269</div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">PCN</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1">RXPREF</div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">PLAN ID</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1">RH72123</div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">GRP</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1">
                RIVERHEALTH
              </div>
            </div>
            <div className="grid grid-cols-1 tablet:grid-cols-3 items-center">
              <div className="col-span-1 font-light text-[#717171]">Support Line</div>
              <div className="col-span-2 text-sm font-ptSans font-bold mobile:-mt-1 z-10">
                <a href="tel:888-666-7271" className="text-river-blue">
                  888-666-7271
                </a>
              </div>
            </div>
          </div>

          <div className="absolute inset-0 flex flex-col items-center overflow-hidden !my-0 rounded-[18px]">
            <RiverLogo className="w-[210px] h-[150px] text-river-blue fill-river-blue ml-auto my-auto opacity-10 mr-4" />

            <span className="w-[200px] h-[200px] bg-river-blue rounded-full absolute -top-[120px] -right-[40px]" />

            <span className="absolute top-[12px] right-[12px] text-[13px] text-white">
              Prescription card
            </span>

            <span
              className="w-[150px] h-[150px] border border-dashed border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[90px] -right-[80px]"
            />

            <span
              className="w-[150px] h-[150px] border border-dashed border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[120px] right-[0px]"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PrescriptionCard;
