import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { PopupButton } from '@typeform/embed-react';
import { Button, Graphic, Modal } from '../..';
import { paths } from '../../../routes';
import Icon, { IconSprite } from '../../Icon/Icon';
import { AuthUser } from '../../../context/authContext';

type ModalProps = {
  user: AuthUser;
  onCloseModal: () => void;
};

function ActivateCardPrompt({ onCloseModal, user }: ModalProps) {
  const navigate = useNavigate();

  const instructions = [
    'Call the toll free number located on the sticker of your River Health Card and follow the prompts to activate your card.',
    'Enter the 16-digit number found on the front of your card.',
    'Enter the last four digits of your Member ID. Your Member ID can be found on the bottom of this screen.',
    'Enter the three-digit CVV code. The CVV code is the last three digits of the number located on the signature panel on the back of your card.',
    'Creating a PIN number for you card is optional.',
  ];

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white tablet:w-[600px] laptop:w-[800px]">
        <div className="flex flex-col px-6 tablet:px-12 py-7 pb-7 items-center">
          <h3
            className="text-river-black text-[22px] tablet:text-xl font-bold mb-3 tablet:mb-2 inline-flex 
            text-center items-center px-2 tablet:px-0"
          >
            Activate Your River Health Card
          </h3>

          <div
            className="bg-[#EEFBFF] rounded-full text-xs tablet:text-[13px] leading-[22px] tablet:leading-[24px] px-4 py-1 flex 
            justify-center items-center text-center"
          >
            <span
              className="bg-white border border-[#ADDAF3]/70 rounded-full w-[22px] h-[22px] tablet:w-[20px] tablet:h-[20px] 
              flex justify-center items-center mr-2 shrink-0"
            >
              <span className="bg-river-blue rounded-full w-[12px] h-[12px] tablet:w-[10px] tablet:h-[10px] block">
                <Icon id="exclamation" className="text-white" />
              </span>
            </span>
            Your River Health Card only needs to be activated once.
          </div>

          <div className="mt-7 flex flex-col space-y-5 tablet:space-y-6 text-base leading-[24px]">
            {instructions.map((notice, index) => (
              <div className="flex flex-row space-x-4" key={index}>
                <span
                  className="bg-river-black rounded-full w-[28px] h-[28px] flex justify-center 
                  items-center text-white shrink-0 font-black text-[13px]"
                >
                  {index + 1}
                </span>
                <p>{notice}</p>
              </div>
            ))}
          </div>

          <div className="bg-[#FFF9F5] border border-river-deep-orange/30 rounded-xl mt-7 p-4 flex flex-row tablet:space-x-4 w-full">
            <span className="hidden bg-river-deep-orange rounded-full w-[38px] h-[38px] tablet:flex justify-center items-center shrink-0">
              <IconSprite id="icon-files-stacked" className="text-white w-[24px] h-[24px]" />
            </span>
            <div>
              <p className="block text-[#717171] text-xs mb-2 font-light">
                Other resources you may find helpful:
              </p>

              <a
                className="text-[15px] tablet:text-sm block font-medium hover:text-river-deep-orange hover:underline"
                href="https://www.helloriver.com/support/river-health-card-support-river-health"
                target="_blank"
                rel="noreferrer"
              >
                What can I use my River Health Card for?
              </a>
              <a
                className="text-[15px] tablet:text-sm block font-medium hover:text-river-deep-orange hover:underline"
                href="https://www.helloriver.com/support/prescriptions-support-river-health"
                target="_blank"
                rel="noreferrer"
              >
                How do I pick up prescriptions using my River Health benefit?
              </a>

              <p className="font-medium text-xs text-[#666666] mt-4">
                Need more help? Visit the{' '}
                <Link
                  to={paths.SUPPORT_URL_PATH}
                  onClick={() => {
                    onCloseModal();
                    navigate(paths.SUPPORT_URL_PATH);
                  }}
                  className="text-river-deep-orange hover:underline"
                >
                  {' '}
                  Support Center
                </Link>
              </p>
            </div>
          </div>

          <div className="mt-6 text-[#717171] text-lg tablet:text-[20px]">
            Member ID{' '}
            <strong className="text-river-black">{user?.insurance?.subscriberId || 'N/A'}</strong>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ActivateCardPrompt;
