import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Icon } from '..';

type TabProps = {
  id: string;
  defaultActiveKey: string;
  className?: string;
  children: React.ReactElement<TabContentProps> | React.ReactElement<TabContentProps>[];
};

type TabContentProps = {
  eventKey: string;
  children: React.ReactNode;
  title: string;
};

export function TabContent({ eventKey, title, children }: TabContentProps) {
  return null;
}

function Tab({ id, defaultActiveKey, children, className }: TabProps) {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const [tabPressed, setTabPressed] = useState(!isMobile || Boolean(activeKey));

  let tabContents = [];

  if (Array.isArray(children)) {
    tabContents = children.filter(child => child.type === TabContent).map(child => child.props);
  } else {
    tabContents = [children.props];
  }

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    setTabPressed(isMobile ? !tabPressed : true);
  };

  const renderTabContent = (tab: TabContentProps) => {
    return (
      <div
        key={tab.eventKey}
        className={`px-6 py-10 h-full laptop:h-auto tablet:px-8 tablet:py-10 laptop:px-16 laptop:py-10 ${
          activeKey !== tab.eventKey && 'hidden'
        }`}
        id={tab.eventKey}
        role="tabpanel"
        aria-labelledby={`${tab.eventKey}Tab`}
      >
        {tab.children}
      </div>
    );
  };

  return (
    <>
      <ul
        className="flex flex-wrap space-y-5 tablet:border-b tablet:border-[#e9ecef] 
        tablet:px-8 tablet:space-x-0 tablet:space-y-0 laptop:px-16 laptop:space-x-5 laptop:space-y-0"
        role="tablist"
        id={id}
      >
        {tabContents.length > 0 &&
          tabContents.map(tab => (
            <li
              className="rounded-lg shadow-[0_0px_15px_0px_rgba(0,0,0,0.1)] bg-white w-full 
              tablet:shadow-none tablet:bg-transparent tablet:rounded-none tablet:w-fit"
              key={tab.eventKey}
            >
              <button
                id={`${tab.eventKey}Tab`}
                onClick={() => handleTabChange(tab.eventKey)}
                type="button"
                role="tab"
                aria-controls={tab.eventKey}
                aria-selected="false"
                className={`w-full h-fit p-6 text-left text-river-black inline-flex items-center justify-between text-lg font-poppins-regular rounded-t-lg border-b-2 border-transparent 
                tablet:hover:border-gray-300 tablet:text-gray-400 tablet:text-sm tablet:text-center tablet:w-auto tablet:pt-7 tablet:pb-2 tablet:px-4 ${
                  activeKey === tab.eventKey &&
                  tabPressed &&
                  '!font-poppins-bold !text-river-black rounded-t-lg border-[#e9ecef] border-b-[1px] active tablet:!font-poppins-regular tablet:border-b-2 tablet:border-river-black tablet:hover:!border-river-black'
                }`}
              >
                {tab.title}
                <Icon
                  id="tabDropdown"
                  className={`inline-block float-right tablet:hidden ${
                    activeKey === tab.eventKey && tabPressed && 'rotate-180 tablet:rotate-0'
                  }`}
                />
              </button>
              <div
                id={`${id}Content`}
                className={`${!tabPressed && 'hidden'} flex-auto tablet:hidden`}
              >
                {renderTabContent(tab)}
              </div>
            </li>
          ))}
      </ul>

      <div id={`${id}Content`} className="hidden flex-auto tablet:block laptop:overflow-y-auto">
        {tabContents.length > 0 && tabContents.map(tab => renderTabContent(tab))}
      </div>
    </>
  );
}

Tab.defaultProps = {
  className: '',
};

export default Tab;
