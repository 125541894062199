/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, FocusEvent } from 'react';

type InputType = 'text' | 'email' | 'url' | 'password' | 'number' | 'search' | 'tel';

export type InputProps = {
  name: string;
  className?: string;
  value?: string;
  id?: string;
  type?: InputType;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  maxLength?: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  [x: string]: any;
};

function Input({
  className,
  type,
  name,
  id,
  disabled,
  error,
  onBlur,
  onFocus,
  placeholder,
  maxLength,
  value,
  onChange,
  ...props
}: InputProps) {
  return (
    <input
      {...props}
      className={`${className} ${
        error ? '!border-river-red' : ''
      } disabled:border-[#dfe5eb] disabled:bg-[#f5f7fa]`}
      type={type}
      name={name}
      aria-label={name}
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      maxLength={maxLength}
    />
  );
}

Input.defaultProps = {
  id: '',
  className: '',
  type: 'text',
  placeholder: '',
  value: undefined,
  disabled: false,
  error: false,
  maxLength: undefined,
  onBlur: undefined,
  onFocus: undefined,
};

export default Input;
