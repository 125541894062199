import * as React from 'react';
import TextareaAutoSize from 'react-autosize-textarea';

type AutoSizeInputProps = {
  name: string;
  id?: string;
  value: string;
  error?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  rows?: number;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onResize?: (event: Event) => void;
};

function AutoSizeTextBoxInput({
  name,
  value,
  id,
  error,
  inputRef,
  placeholder,
  className,
  onBlur,
  onFocus,
  disabled,
  onChange,
  onKeyDown,
  onResize,
  rows,
}: AutoSizeInputProps) {
  return (
    <TextareaAutoSize
      id={id}
      className={className}
      ref={inputRef}
      onResize={onResize}
      maxRows={7}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  );
}

AutoSizeTextBoxInput.defaultProps = {
  disabled: false,
  className: '',
  placeholder: '',
  error: false,
  onBlur: undefined,
  id: undefined,
  onFocus: undefined,
  onResize: undefined,
  onKeyDown: undefined,
  rows: undefined,
  inputRef: undefined,
};

export default AutoSizeTextBoxInput;
