/* eslint-disable no-cond-assign */
import DOMPurify from 'dompurify';
import { marked } from 'marked';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

function markdown(src: string, options?: marked.MarkedOptions) {
  const linkRenderer = () => {
    const renderer = new marked.Renderer();
    const paragraphRenderer = renderer.paragraph;

    renderer.paragraph = text => {
      let textToRender = paragraphRenderer.call(renderer, text);

      const targetRegex = /{:(.*?)}/g;

      const linkRegexPattern = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g;

      const targets: string[] = [];

      const links = [];

      let matchedTarget;

      let matchedLink;

      while ((matchedTarget = targetRegex.exec(textToRender)) !== null) {
        targets.push(matchedTarget[1].trim().replaceAll('&quot;', '"'));
      }

      if (targets.length > 0) {
        while ((matchedLink = linkRegexPattern.exec(textToRender)) !== null) {
          links.push(matchedLink[0]);
        }

        links.forEach((fndLink, index) => {
          const link = fndLink.replace(/^<a /, `<a ${targets[index]} rel='nofollow' `);
          textToRender = textToRender.replaceAll(targetRegex, '').replace(fndLink, link);
        });
      }

      return textToRender;
    };

    return renderer;
  };

  marked.setOptions({
    renderer: linkRenderer(),
  });

  return DOMPurify.sanitize(marked.parse(src));
}

export default markdown;
