import * as React from 'react';
import isValidURL from '../../utils/isValidURL';

type ImageProps = {
  src: string;
  bgColor?: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
  onError?: (event: React.UIEvent<HTMLImageElement>) => void;
};

function Image({ src, alt, bgColor, className, onClick, onError }: ImageProps) {
  const [imagePath, setImagePath] = React.useState(src);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const mountedRef = React.useRef(false);

  React.useEffect(() => {
    mountedRef.current = true;

    if (mountedRef.current) {
      if (src && !isValidURL(src)) {
        (async function loadImage() {
          const image = (await import(`../../assets/images/${src}`)).default;
          setImagePath(image);
        })();
      } else {
        setImagePath(src);
      }
    }

    return () => {
      mountedRef.current = false;
    };
  }, [src]);

  return (
    <div
      style={{ backgroundColor: bgColor || '#cfdeb9' }}
      className={`overflow-hidden ${className || ''}`}
    >
      {imagePath && (
        <img
          role="presentation"
          onLoad={() => {
            setIsLoaded(true);
          }}
          onClick={onClick}
          className={`w-full h-full object-cover ${isLoaded ? 'opacity-1' : 'opacity-0'}`}
          alt={alt}
          src={imagePath}
        />
      )}
    </div>
  );
}

Image.defaultProps = {
  bgColor: '#cfdeb9',
  className: '',
  alt: '',
  onClick: undefined,
  onError: undefined,
};

export default Image;
