/* eslint-disable @typescript-eslint/no-shadow */
import { isEmpty } from 'ramda';
import * as React from 'react';
import { Input, Select } from '../..';
import US_STATES from '../../../utils/usStates';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type AddressInputBuilderProps = {
  id: string;
  label: string;
  value: string[];
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function AddressInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: AddressInputBuilderProps) {
  const [address, setAddress] = React.useState(() => {
    if (value && value.length > 0) {
      return {
        // streetAddress: value[0],
        // apartment: value[1],
        // city: value[2],
        state: value[0],
        // zipCode: value[4],
      };
    }

    return {
      // streetAddress: '',
      // apartment: '',
      // city: '',
      state: '',
      // zipCode: '',
    };
  });

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = event.target;

    setAddress(address => ({ ...address, [name]: value }));

    const answers = Object.values({ ...address, [name]: value });

    const requiredAddress = Object.entries({
      ...address,
      [name]: value,
    }).filter(address => address[0] !== 'apartment');

    if (answers && requiredAddress.every(address => !isEmpty(address[1]))) {
      onInputChange({ questionKey: id, answers });
    } else {
      onInputChange({ questionKey: id, answers: [''] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-3">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>
      <div className="fs-exclude grid grid-cols-2 gap-7">
        {/* <Input
          className="col-span-2 px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0
          focus:border-b-[1.5px] focus:ring-0"
          name="streetAddress"
          placeholder="Street Address"
          value={address.streetAddress}
          onChange={handleInputsChange}
          error={Boolean(error)}
        /> */}

        {/* <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
          name="apartment"
          placeholder="Apartment"
          value={address.apartment}
          onChange={handleInputsChange}
        /> */}

        {/* 
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
          name="city"
          placeholder="City"
          value={address.city}
          onChange={handleInputsChange}
          error={Boolean(error)}
        /> */}

        <Select
          className="col-span-2 px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
          placeholder={{
            value: '',
            label: 'State',
          }}
          value={address.state}
          options={US_STATES}
          name="state"
          onChange={handleInputsChange}
          error={Boolean(error)}
        />

        {/* <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
          name="zipCode"
          placeholder="Zip Code"
          value={address.zipCode}
          onChange={handleInputsChange}
          error={Boolean(error)}
        /> */}
      </div>

      {error && <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

AddressInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default AddressInputBuilder;
