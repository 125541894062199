import * as React from 'react';
import { Input } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type InputBuilderProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function InputBuilder({ id, label, value, error, notice, name, onInputChange }: InputBuilderProps) {
  const [inputValue, setInputValue] = React.useState(value);

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onInputChange({
      questionKey: id,
      answers: [event.target.value],
    });
  };

  return (
    <div>
      <div className="flex flex-col mb-2">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>
      <Input
        className="fs-exclude px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0"
        name={name}
        value={inputValue}
        onChange={handleInputsChange}
        error={Boolean(error)}
      />
      {error && <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

InputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default InputBuilder;
