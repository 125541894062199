const formatArrayIntoSentence = (array: string[]): string => {
  let outStr = '';
  if (array.length === 1) {
    [outStr] = array;
  } else if (array.length === 2) {
    outStr = array.join(' and ');
  } else if (array.length > 2) {
    outStr = `${array.slice(0, -1).join(', ')} and ${array.slice(-1)}`;
  }
  return outStr;
};

export default formatArrayIntoSentence;
