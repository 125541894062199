import React from 'react';
import { Button, Icon, Modal } from '../..';

type ModalProps = {
  open: boolean;
  onCloseModal: () => void;
};

function EmergencyNotice({ onCloseModal, open }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show={open}>
      <div className="rounded-xl bg-white tablet:w-[400px]">
        <div className="flex flex-col p-8 pt-8 pb-10 items-center text-center">
          <span className="w-24 h-24 bg-[#FFE0E0] rounded-full flex">
            <Icon id="iconEmergencyCall" className="w-14 self-center mx-auto" />
          </span>
          <p className="text-river-black text-sm mb-2 font-normal mt-6">
            Please call{' '}
            <a className="text-river-blue font-medium" href="tel:911">
              911{' '}
            </a>{' '}
            or{' '}
            <a className="text-river-blue font-medium" href="tel:988">
              988{' '}
            </a>
            immediately. You can also go to your local emergency department
          </p>

          <div className="mt-12 tablet:mt-5">
            <Button label="Okay" onClick={onCloseModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmergencyNotice;
