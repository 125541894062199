import * as React from 'react';
import { Link } from 'react-router-dom';
import { PopupButton } from '@typeform/embed-react';
import { Icon } from '../..';
import { paths } from '../../../routes';

type PaymentProps = {
  paymentDetails: {
    expirationDate: string;
    last4Digits: string;
    postalCode: string;
  };
};

function PaymentMethodForm({ paymentDetails }: PaymentProps) {
  return (
    <div className="h-full relative">
      <div
        className="flex flex-col tablet:flex-row flex-wrap items-start 
        space-y-3 tablet:items-center tablet:space-x-8 tablet:space-y-0"
      >
        <span className="font-poppins-medium bg-[#EBEBEB] px-[16px] text-[12px] text-[#838383] rounded-[4px]">
          DEFAULT
        </span>
        <div className="flex">
          <Icon id="creditCard" className="w-7" />
          <span className="whitespace-pre text-[20px]"> •••• •••• •••• </span>
          <span>{paymentDetails.last4Digits}</span>
        </div>
        <span className="inline-flex tablet:!mt-5 tablet:!ml-1 laptop:!mt-0 laptop:!ml-14">
          Expires {paymentDetails.expirationDate}
        </span>
      </div>

      <div className="mt-10">
        <Link to={paths.PAYMENT_METHOD_URL_PATH} className="text-river-blue text-[15px]">
          Edit payment info
        </Link>
      </div>

      <hr className="border-t-[1px] border-[#e9ecef] !mt-6 !mb-[25px]" />

      <div className="!my-0">
        <h3 className="font-semibold uppercase mb-2">REIMBURSEMENTS</h3>

        <div>
          Click the button below to request a reimbursement for prescriptions, labs and in-person
          visits.
        </div>

        <PopupButton id="Zd7M4QYX">
          <button
            type="button"
            className="bg-[#EEFBFF] text-river-blue px-8 py-2 hover:opacity-[0.85] text-sm rounded-full 
            border border-river-blue mt-8 font-medium"
          >
            Request Reimbursement
          </button>
        </PopupButton>
      </div>
    </div>
  );
}

export default PaymentMethodForm;
