import React, { ChangeEvent, FocusEvent } from 'react';

type SelectInputProps = {
  className?: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  placeholder: {
    value: string | number;
    label: string;
  };
  value: string;
  options: Array<{ value: string | number; label: string }>;
  onChange: (value: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
};

function SelectInput({
  className,
  name,
  disabled,
  error,
  placeholder,
  value,
  onChange,
  onBlur,
  options,
}: SelectInputProps) {
  return (
    <select
      name={name}
      value={value}
      disabled={disabled}
      className={`${className} ${error ? 'border-river-red' : ''} ${
        value ? 'text-river-black font-poppins-medium' : 'text-gray-300  font-poppins-light'
      }`}
      onBlur={onBlur}
      onChange={!disabled ? onChange : undefined}
    >
      <option className="disabled" value={placeholder.value} disabled>
        {placeholder.label}
      </option>
      {options &&
        options
          .filter(option => Boolean(option.value))
          .map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
    </select>
  );
}

SelectInput.defaultProps = {
  className: '',
  onBlur: undefined,
  disabled: false,
  error: false,
};

export default SelectInput;
