/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { RadioInput } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type InputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  inputs: Array<{
    id: string;
    priority: number;
    text: string;
  }>;
  onInputChange: (answers: Answer) => void;
};

function CustomChipInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  inputs,
  onInputChange,
}: InputBuilderProps) {
  const [checkedItem, setCheckedItem] = React.useState<string>(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItem(value);
      onInputChange({ questionKey: id, answers: [value] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        {notice && <span className="text-sm text-river-blue mb-1 font-medium">{notice}</span>}
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
      </div>
      <div className="grid grid-cols-2 gap-7">
        {inputs &&
          inputs.map(input => (
            <label
              key={`${id}_${input.id}`}
              htmlFor={`${id}_${input.id}`}
              className={`text-sm cursor-pointer rounded-full w-full 
              p-5 flex flex-row items-center justify-center ${
                checkedItem === input.id ? 'bg-river-blue' : 'bg-[#EDF9FF]'
              }`}
            >
              <RadioInput
                id={`${id}_${input.id}`}
                name={id}
                value={input.id}
                checked={checkedItem === input.id}
                onChange={handleInputChange}
                className="align-middle cursor-pointer opacity-0"
              />{' '}
              <span
                className={`align-middle ${
                  checkedItem === input.id ? 'text-white' : 'text-river-blue'
                }`}
              >
                {input.text}
              </span>
            </label>
          ))}
      </div>
      {error && <span className="text-river-red text-left text-xs mt-3 inline-flex">{error}</span>}
    </div>
  );
}

CustomChipInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CustomChipInputBuilder;
