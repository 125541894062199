/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ReactComponent as ImageHairSkinAcneGraphic } from '../../assets/images/image_skin_condition_acne.svg';
import { ReactComponent as ImageHairSkinRosaceaGraphic } from '../../assets/images/image_skin_condition_rosacea.svg';
import { ReactComponent as ImageHairSkinDarkSpotsGraphic } from '../../assets/images/image_skin_condition_dark_spots.svg';
import { ReactComponent as ImageHairSkinEczemaGraphic } from '../../assets/images/image_skin_condition_eczema.svg';
import { ReactComponent as ImageHairSkinSomethingElseGraphic } from '../../assets/images/image_hair_skin_condition_something_else.svg';
import { ReactComponent as ImageHairSkinLossGraphic } from '../../assets/images/image_hair_condition_hair_loss.svg';
import { ReactComponent as ImageBehavioralHealthAnxiety } from '../../assets/images/image_behavioral_health_anxiety.svg';
import { ReactComponent as ImageBehavioralHealthDepression } from '../../assets/images/image_behavioral_health_depression.svg';
import { ReactComponent as ImageBehavioralHealthAnxietyMobile } from '../../assets/images/image_behavioral_health_anxiety_mobile.svg';
import { ReactComponent as ImageBehavioralHealthDepressionMobile } from '../../assets/images/image_behavioral_health_depression_mobile.svg';
import { ReactComponent as ImageHairSkinAcneGraphicMobile } from '../../assets/images/image_skin_condition_acne_mobile.svg';
import { ReactComponent as ImageHairSkinRosaceaGraphicMobile } from '../../assets/images/image_skin_condition_rosacea_mobile.svg';
import { ReactComponent as ImageHairSkinDarkSpotsGraphicMobile } from '../../assets/images/image_skin_condition_dark_spots_mobile.svg';
import { ReactComponent as ImageHairSkinEczemaGraphicMobile } from '../../assets/images/image_skin_condition_eczema_mobile.svg';
import { ReactComponent as ImageHairSkinSomethingElseGraphicMobile } from '../../assets/images/image_hair_skin_condition_something_else_mobile.svg';
import { ReactComponent as ImageHairSkinLossGraphicMobile } from '../../assets/images/image_hair_condition_hair_loss_mobile.svg';
import { ReactComponent as ImageSubscriptionNoPaymentGraphic } from '../../assets/images/image_onboarding_employee_subscription_no_payment_graphic.svg';
import { ReactComponent as ImageVirtualVisitsClosedInfoHeader } from '../../assets/images/image_virtual_visits_closed_info_header.svg';
import { ReactComponent as ImageSexualHealthStdDiagnosis } from '../../assets/images/image_sexual_health_std_diagnosis.svg';
import { ReactComponent as ImageSexualHealthStdTest } from '../../assets/images/image_sexual_health_std_test.svg';
import { ReactComponent as ImageSexualHealthGenitalHerpes } from '../../assets/images/image_sexual_health_genital_herpes.svg';
import { ReactComponent as ImageSexualHealthColdSores } from '../../assets/images/image_sexual_health_cold_sores.svg';
import { ReactComponent as ImageSexualHealthStdDiagnosisMobile } from '../../assets/images/image_sexual_health_std_diagnosis_mobile.svg';
import { ReactComponent as ImageSexualHealthStdTestMobile } from '../../assets/images/image_sexual_health_std_test_mobile.svg';
import { ReactComponent as ImageSexualHealthGenitalHerpesMobile } from '../../assets/images/image_sexual_health_genital_herpes_mobile.svg';
import { ReactComponent as ImageSexualHealthColdSoresMobile } from '../../assets/images/image_sexual_health_cold_sores_mobile.svg';
import { ReactComponent as ImageClinicManualBookingGraphic } from '../../assets/images/image_clinic_manual_booking_graphic.svg';
import { ReactComponent as ImageCareHistoryTexture } from '../../assets/images/image_care_history_texture.svg';
import { ReactComponent as ImageCareHistoryMedicalVisits } from '../../assets/images/image_care_history_medical_visits.svg';
import { ReactComponent as ImageCareHistoryMedHistory } from '../../assets/images/image_care_history_medication_history.svg';
import { ReactComponent as ImageCareHistoryLabWorks } from '../../assets/images/image_care_history_lab_works.svg';
import { ReactComponent as ImageCareHistoryNotes } from '../../assets/images/image_care_history_notes.svg';
import { ReactComponent as ImageVideoCallVisitGraphicIllustration } from '../../assets/images/image_video_call_visit_graphic_illustration.svg';
import { ReactComponent as ImageChatCardTexture } from '../../assets/images/image_chat_card_texture.svg';
import { ReactComponent as ImageConfirmBookedAppointment } from '../../assets/images/image-confirm-booked-appointment.svg';
import { ReactComponent as ImageOnboardingComplete } from '../../assets/images/image_onboarding_complete.svg';
import { ReactComponent as ImageApptInfoCompletePrompt } from '../../assets/images/image_appointment_info_complete_prompt.svg';
import { ReactComponent as ImageBrokerBanner } from '../../assets/images/image_broker_banner.svg';
import { ReactComponent as ImageDemoMaskBg01 } from '../../assets/images/image_demo_mask_bg_01.svg';
import { ReactComponent as ImageDemoMaskBg02 } from '../../assets/images/image_demo_mask_bg_02.svg';
import { ReactComponent as ImageDemoMaskBg03 } from '../../assets/images/image_demo_mask_bg_03.svg';
import { ReactComponent as ImageNoMessage } from '../../assets/images/image_no_message_illustration.svg';
import { ReactComponent as ImageUrgentCareClinic } from '../../assets/images/image_urgent_care_clinic.svg';

type GraphicProps = {
  id: string;
  className?: string;
  onClick?: () => void;
};

const allGraphics: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any> = {
  graphicAcne: ImageHairSkinAcneGraphic,
  graphicRosacea: ImageHairSkinRosaceaGraphic,
  graphicDarkSpots: ImageHairSkinDarkSpotsGraphic,
  graphicEczema: ImageHairSkinEczemaGraphic,
  graphicHairLoss: ImageHairSkinLossGraphic,
  graphicSomethingElse: ImageHairSkinSomethingElseGraphic,
  graphicAcneMobile: ImageHairSkinAcneGraphicMobile,
  graphicRosaceaMobile: ImageHairSkinRosaceaGraphicMobile,
  graphicDarkSpotsMobile: ImageHairSkinDarkSpotsGraphicMobile,
  graphicEczemaMobile: ImageHairSkinEczemaGraphicMobile,
  graphicHairLossMobile: ImageHairSkinLossGraphicMobile,
  graphicSomethingElseMobile: ImageHairSkinSomethingElseGraphicMobile,
  graphicImDepressed: ImageBehavioralHealthDepression,
  graphicImAnxious: ImageBehavioralHealthAnxiety,
  graphicImDepressedMobile: ImageBehavioralHealthDepressionMobile,
  graphicImAnxiousMobile: ImageBehavioralHealthAnxietyMobile,
  graphicNoPaymentSubscription: ImageSubscriptionNoPaymentGraphic,
  graphicVirtualVisitsClosedInfoHeader: ImageVirtualVisitsClosedInfoHeader,
  graphicSexualHealthStdDiagnosis: ImageSexualHealthStdDiagnosis,
  graphicSexualHealthStdTest: ImageSexualHealthStdTest,
  graphicSexualHealthGenitalHerpes: ImageSexualHealthGenitalHerpes,
  graphicSexualHealthColdSores: ImageSexualHealthColdSores,
  graphicSexualHealthStdDiagnosisMobile: ImageSexualHealthStdDiagnosisMobile,
  graphicSexualHealthStdTestMobile: ImageSexualHealthStdTestMobile,
  graphicSexualHealthGenitalHerpesMobile: ImageSexualHealthGenitalHerpesMobile,
  graphicSexualHealthColdSoresMobile: ImageSexualHealthColdSoresMobile,
  graphicClinicManualBookingGraphic: ImageClinicManualBookingGraphic,
  graphicCareHistoryTexture: ImageCareHistoryTexture,
  graphicCareHistoryMedicalVisits: ImageCareHistoryMedicalVisits,
  graphicCareHistoryMedHistory: ImageCareHistoryMedHistory,
  graphicCareHistoryLabWorks: ImageCareHistoryLabWorks,
  graphicCareHistoryNotes: ImageCareHistoryNotes,
  graphicVideoCallVisitIllustration: ImageVideoCallVisitGraphicIllustration,
  graphicChatCardTexture: ImageChatCardTexture,
  graphicConfirmBookedAppointment: ImageConfirmBookedAppointment,
  graphicOnboardingComplete: ImageOnboardingComplete,
  graphicApptInfoCompletePrompt: ImageApptInfoCompletePrompt,
  imageBrokerBanner: ImageBrokerBanner,
  graphicDemoMaskBg01: ImageDemoMaskBg01,
  graphicDemoMaskBg02: ImageDemoMaskBg02,
  graphicDemoMaskBg03: ImageDemoMaskBg03,
  graphicNoMessage: ImageNoMessage,
  graphicUrgentCareClinic: ImageUrgentCareClinic,
};

function Graphic({ id, ...rest }: GraphicProps) {
  const SelectedGraphic = allGraphics[id];
  return SelectedGraphic ? <SelectedGraphic id={id} {...rest} /> : null;
}

Graphic.defaultProps = {
  className: '',
  onClick: () => false,
};

export default Graphic;
