/* eslint-disable react/button-has-type */
import React from 'react';
import { Icon } from '..';

type ButtonProps = {
  id?: string;
  label: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (value: any) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
};

function Button({ id, label, type, className, disabled, loading, onClick }: ButtonProps) {
  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      className={`text-sm rounded-full bg-river-blue h-14 min-w-[230px] text-white 
      justify-center inline-flex items-center hover:opacity-[0.85] cursor-pointer 
        ${
          loading ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed'
        }  disabled:text-gray-300 disabled:opacity-[0.85]
        ${className}`}
    >
      {!(disabled && loading) ? label : 'Please wait...'}

      {disabled && loading && <Icon id="loader" className="w-5 h-5 ml-4" />}
    </button>
  );
}

Button.defaultProps = {
  id: '',
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
  onClick: undefined,
};

export default Button;
