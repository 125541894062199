/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 *  ----------------------------------------------------------------------------
 *  ----------------------------------------------------------------------------
 * This has nothing to do with the conventional Context API.
 * It just contains helper functions for actions such as api calls.
 * If you find a better way to group this functions or methods feel free to
 * refactor and improve the code.
 *
 * Please dont misuse the FREEDOM 😉
 * ----------------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

import { isEmpty, pickBy, pluck, prop, sortBy, uniq, uniqBy, equals } from 'ramda';
import axios from 'axios';
import * as XLSX from 'xlsx';
import formatArrayIntoSentence from '../utils/formatArrayIntoSentence';
import apigateway from '../utils/apigateway';
import { AssignedProvider } from './authContext';
import US_STATES from '../utils/usStates';
import sleep from '../utils/sleep';
import readAWSS3File from '../utils/readAWSS3File';
import env from '../utils/env';

export const VISIT_SUBMIT_LOADER_PATH = '/assets/lottie/lf20_X9O4Xi.json';

export type QuestionType =
  | 'OPEN-TEXT'
  | 'OPEN-TEXTAREA'
  | 'OPEN-DATE'
  | 'OPEN-ADDRESS'
  | 'OPEN-NUMBER'
  | 'OPEN-BLOOD-PRESSURE'
  | 'OPEN-HEIGHT'
  | 'OPEN-WEIGHT'
  | 'BINARY'
  | 'BINARY-SELECT'
  | 'MULTIPLE-CHOICE'
  | 'OPEN-IMAGE'
  | 'CUSTOM-BINARY-SELECT'
  | 'CUSTOM-BINARY'
  | 'OPEN-FILE'
  | 'OPEN-FILES'
  | 'BREAK-POINT';

export const LAB_TEST_STATUS = {
  REQUESTED: 'REQUESTED',
  SENT_TO_LAB: 'SENT-TO-LAB',
  RECEIVED_REPORT: 'RECEIVED-REPORT',
};

export type Question = {
  id: string;
  type: QuestionType;
  priority: number;
  text: string;
  alternateText?: string;
  hint?: string;
  error?: string;
  options?: Array<{
    id: string;
    priority: number;
    text: string;
    alternateText?: string;
    hint?: string;
    followups?: Question[];
  }>;

  notice?: string;
  option?: string;
  children?: Array<{
    id: string;
    priority: number;
    option: string;
    notice?: string;
  }>;
};

export type Answer = {
  questionKey: string;
  answers: Array<string>;
};

export type QuestionAnswers = {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  name: string;
  patientUserId: string;
  provider: AssignedProvider;
  answers: Array<Answer>;
  status: string;
  prescription: string;
  prescriptions: Prescription[];
  attachments: string[];
  notesForPatient: string;
  calls: Array<{
    id: string;
    acceptedAt: string;
    createdAt: string;
    link: string;
  }>;
  additionalQuestions: Array<{
    id: string;
    question: string;
    answer: string;
    createdAt: string;
    answeredAt: string;
  }>;
  labTests: Array<LabTest> | null;
  followUps: Array<Answer>;
  referrals: Array<ReferralType> | null;
  abandonedReason: string;
  abandonedReasonAdditionalDetails: string;
};

export type QuestionsTabs = { id: string; name: string };

export type ReferralType = {
  id: string;
  describeRecord: string;
  icdCode: string;
  kindOfAppointment: string;
  nameOfClinic: string;
  notes: string;
  otherInformation: string;
  reason: string;
  reasonForTherapist: string;
  specialtyType: string;
  timeOfVisit: string;
  type: string;
  typeOfRecord: string;
  createdAt: string;
  updatedAt: string;
  provider: AssignedProvider;
};

export type Visit = {
  name: string;
  type: string;
  tabs: Record<string, { name: string; questionKeys: string[] }>;
};

export type LabTest = {
  id: string;
  testName?: string;
  types?: any;
  answers?: any;
  patientId: string;
  status: 'REQUESTED' | 'SENT-TO-LAB' | 'RECEIVED-REPORT';
  url: string;
  signedUrl?: string;
  createdAt: string;
  updatedAt: string;
};

export type Prescription = {
  id: string;
  prescriptionPhotonId: string;
  medicationName: string;
  dose: string;
  numberToBeTaken: number;
  route: string;
  frequency: string;
  numberDispensed: number;
  numberOfRefills: number;
  daysSupply: number;
  dispenseUnit: string;
  doNotSubstitute: boolean;
  createdAt: string;
  instructions: string;
};

export type Note = {
  id: string;
  text: string;
  date: string;
  authorId: string;
  author?: AssignedProvider;
  attachment: string;
};

export type ProviderListing = {
  firstName: string;
  middleName: string;
  lastName: string;
  ratingsAvg: number;
  ratingsCount: number;
  degrees: string[];
  languages: string[];
  specialties: Array<{
    isPrimary: boolean;
    providerName: string;
    providerType: string;
    boardSpecialty: string;
    display: string;
  }>;
  locations: Array<{
    confidence: number;
    address: string;
    phoneNumbers: Array<{ phone: string; details: string }>;
    addressDetails: {
      street: string;
      city: string;
      state: string;
      zip: string;
    };
  }>;
  npi: string;
};

export type ClinicListing = {
  name: string;
  googleMapsLink: string;
  phoneNumbers: Array<{ phone: string; details: string }>;
  address: string;
  latitude: number;
  longitude: number;
};

export const VISIT_TYPE_CONSTANTS = {
  BIRTH_CONTROL: 'BIRTH-CONTROL',
  SICK_VISITS: 'SICK',
  UTI_VISITS: 'UTI',
  SEXUAL_HEALTH_VISITS: 'SEXUAL-HEALTH',
  STD_DIAGNOSIS_VISITS: 'STD-DIAGNOSIS', // Under Sexual Health
  STD_TEST_VISITS: 'STD-TEST', // Under Sexual Health
  COLD_SORES_VISITS: 'COLD-SORES', // Under Sexual Health
  GENITAL_HERPES_VISITS: 'GENITAL-HERPES', // Under Sexual Health
  BEHAVIORAL_VISITS: 'MENTAL-HEALTH',
  HAIR_SKIN_VISITS: 'HAIR-SKIN',
  VAGINAL_VISITS: 'VAGINAL-INFECTIONS',
  MENSTRUAL_VISITS: 'MENSTRUAL-IRREGULARITIES',
  ERECTILE_VISITS: 'ERECTILE-DYSFUNCTION',
  RX_REFILLS: 'RX-REFILLS',
  SOMETHING_ELSE: 'SOMETHING-ELSE',
};

export const visitTypes: Visit[] = [
  {
    name: 'Birth Control',
    type: VISIT_TYPE_CONSTANTS.BIRTH_CONTROL,
    tabs: {
      patientInfo: {
        name: 'Patient Info',
        questionKeys: ['ADDRESS'],
      },
      healthHistory: {
        name: 'Health History',
        questionKeys: [
          'CURRENT_MEDICATION_SUPPLEMENTS',
          'PREGNANT_BREASTFEEDING_OR_POSTPARTUM',
          'FIRST_DATE_OF_LAST_PERIOD',
          'SMOKE_CIGARETTES_VAPE_OR_TOBACCO',
          'VAPE_SMOKING_TIMES',
          'CIGAR_SMOKING_COUNT',
          'MEDICAL_CONDITIONS',
          'HAD_SURGERY_BEFORE',
          'TYPE_SURGERY',
          'HEIGHT',
          'WEIGHT',
          'BLOOD_PRESSURE_READING',
          'PAST_BIRTH_CONTROL',
          'PAST_PRESCRIPTION',
          'PAST_PRESCRIPTION_WORKED',
          'PAST_PRESCRIPTION_PROBLEMS',
          'EXPERIENCE_MIGRAINES',
          'EXPERIENCE_AURA_WITH_MIGRAINES',
          'SYMPTOMS_TO_MIGRAINE',
          'USE_OF_HORMONAL_CONTRACEPTIVES',
          'MAIN_BIRTH_CONTROL_REASON',
          'OTHER_BIRTH_CONTROL_REASON',
          'DRUG_ALLERGIES_LIST',
          'MEDICATION_MAILING_DETAILS',
        ],
      },
    },
  },
  {
    name: 'Sick',
    type: VISIT_TYPE_CONSTANTS.SICK_VISITS,
    tabs: {
      patientInfo: {
        name: 'Patient Info',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'DOB',
          'ADDRESS',
          'CURRENT_MEDICATION',
          'CURRENT_ALLERGIES',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'WHEN_SYMPTOMS_STARTED',
          'AROUND_ANYONE_SICK',
          'DESCRIBE_SYMPTOMS',
          'EXPERIENCED_SYMPTOMS_BEFORE',
          'EXPLAIN_EXPERIENCED_SYMPTOMS',
          'MEDICATION_FOR_EXPERIENCED_SYMPTOMS',
          'OTHER_INFO',
        ],
      },
    },
  },
  {
    name: 'UTI Treatment',
    type: VISIT_TYPE_CONSTANTS.UTI_VISITS,
    tabs: {
      patientInfo: {
        name: 'Patient Info',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'AGE_OLD',
          'ADDRESS',
          'GENDER_TYPE',
          'ASSIGNED_SEX_AT_BIRTH',
          'LAST_PERIOD',
          'PREGNANT_OR_POST_MENOPAUSAL',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'SEXUALLY_ACTIVE',
          'VAGINAL_DISCHARGE',
          'STD_TEST_LAST_MONTH',
          'DISCOMFORT_WHEN_URINATING',
          'BLOOD_IN_URINE',
          'CLOUDY_URINE',
          'EXPERIENCING_BLADDER_PAIN',
          'FLANK_PAIN',
          'FEVER_CHILLS',
          'NAUSEOUS_VOMITING',
          'INVOLUNTARY_URINATION',
          'MORE_URINATION',
          'SUDDEN_URINATION',
          'TREATED_FOR_UTI',
          'CURRENTLY_ON_ANTIBIOTICS',
          'IMMUNOSUPPRESSIVE_MEDICATIONS',
          'URINARY_CATHETER',
          'ANY_SURGERIES',
        ],
      },
    },
  },
  {
    name: 'Vaginal Infections',
    type: VISIT_TYPE_CONSTANTS.VAGINAL_VISITS,
    tabs: {
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'AGE_OLD',
          'ADDRESS',
          'SEXUALLY_ACTIVE',
          'DIAGNOSED_WITH_STD',
          'STD_DIAGNOSIS_TYPE',
          'EXPERIENCING_ITCHING',
          'VAGINAL_RASH',
          'BURNING_SENSATION',
          'VULVA_RED_SWOLLEN',
          'VAGINAL_DISCHARGE',
        ],
      },
      healthHistory: {
        name: 'Health History',
        questionKeys: [
          'DIAGNOSED_YEAST_INFECTION',
          'YEAST_INFECTION_PERIOD',
          'PREGNANT',
          'CURRENT_MEDICATIONS',
          'DETAILS_OF_CURRENT_MEDICATIONS',
          'OTHER_HEALTH_ISSUES',
        ],
      },
    },
  },
  {
    name: 'Erectile Dysfunction',
    type: VISIT_TYPE_CONSTANTS.ERECTILE_VISITS,
    tabs: {
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'ADDRESS',
          'AGE_OLD',
          'DURATION_OF_ERECTILE_DYSFUNCTION',
          'START_OF_ERECTILE_PROBLEMS',
          'RELATION_TO_PROBLEMS',
          'PARTIAL_OR_FULL_ERECTIONS',
          'PENETRATE_DURING_SEX',
          'MAINTAIN_ERECTION_DURING_SEX',
          'SEXUAL_DESIRE_RATING',
          'ACTIVE_SEXUAL_PARTNER',
          'ACHIEVE_AN_ORGASM',
          'EJACULATE_NORMALLY',
          'PREMATURE_EJACULATION',
          'EMOTIONAL_CASE_WITH_ERECTION',
          'PAIN_WITH_ERECTIONS',
          'ERECTIONS_BENT',
          'ERECTIONS_BENT_DIRECTION',
        ],
      },
      medicalHistory: {
        name: 'Medical History',
        questionKeys: [
          'TESTOSTERONE_LEVEL_MEASURED',
          'RESULTS_FOR_TESTOSTERONE_LEVEL',
          'PENILE_INJECTION',
          'PENILE_INJECTION_EFFECT',
          'TRIED_AN_ENHANCER',
          'DID_ENHANCER_WORKED',
          'OTHER_TREATMENTS',
          'TREATMENT_METHOD',
          'TREATED_FOR_DIABETES',
          'TREATMENT_METHOD_FOR_SUGAR',
          'TREATED_FOR_BLOOD_PRESSURE',
          'TREATED_FOR_CHOLESTEROL_LEVEL',
          'HEART_DISEASE',
          'HAD_A_STROKE',
          'HARDENING_OF_ARTERIES',
          'TREATED_FOR_DEPRESSION',
          'SURGERIES_IN_PAST',
          'OTHER_MEDICAL_CONDITION',
          'OTHER_MEDICAL_CONDITION_DESCRIPTION',
        ],
      },
    },
  },
  {
    name: 'Menstrual Irregularities',
    type: VISIT_TYPE_CONSTANTS.MENSTRUAL_VISITS,
    tabs: {
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'ADDRESS',
          'LAST_MENSTRUAL_PERIOD',
          'DURATION_OF_MENSTRUAL_PERIOD',
          'CURRENTLY_ON_MENSTRUAL_PERIOD',
          'EXPERIENCING_MENSTRUAL_CRAMPS',
          'PAIN_RATING',
          'EXPERIENCING_PAIN_SOME_BODY_PARTS',
          'ANXIOUS',
          'HEADACHE',
          'LAST_PAP_SMEAR',
          'ABNORMAL_PAP',
          'ABNORMAL_PAP_PERIOD',
        ],
      },
      pastHistory: {
        name: 'Past History',
        questionKeys: [
          'SEXUALLY_ACTIVE',
          'DIAGNOSED_WITH_STI',
          'DIAGNOSIS',
          'OTHER_DIAGNOSIS',
          'TAKING_BIRTH_CONTROL',
          'TYPE_OF_BIRTH_CONTROL',
          'BIRTH_CONTROL_SIDE_EFFECTS',
          'OTHER_SIDE_EFFECTS',
        ],
      },
    },
  },
  {
    name: 'STD Diagnosis',
    type: VISIT_TYPE_CONSTANTS.STD_DIAGNOSIS_VISITS,
    tabs: {
      sexualHistory: {
        name: 'Sexual History',
        questionKeys: [
          'ADDRESS',
          'DIAGNOSIS',
          'OTHER_DIAGNOSIS',
          'PERIOD_FOR_DIAGNOSIS',
          'MEDICATION_FOR_DIAGNOSIS',
          'TYPE_OF_MEDICATION',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: ['SYMPTOMS_FROM_DIAGNOSIS', 'DESCRIBE_SYMPTOMS'],
      },
    },
  },
  {
    name: 'STD Test',
    type: VISIT_TYPE_CONSTANTS.STD_TEST_VISITS,
    tabs: {
      currentRelationships: {
        name: 'Current Relationships',
        questionKeys: ['ADDRESS', 'SEXUALLY_ACTIVE', 'SEX_WITH_PARTNER_DIAGNOSED'],
      },
      sexualHistory: {
        name: 'Sexual History',
        questionKeys: [
          'PARTNER_DIAGNOSIS',
          'OTHER_DIAGNOSIS',
          'TESTED_FOR_STD_14_DAYS',
          'STD_TEST_STATUS',
        ],
      },
    },
  },
  {
    name: 'Hair & Skin',
    type: VISIT_TYPE_CONSTANTS.HAIR_SKIN_VISITS,
    tabs: {
      getStarted: {
        name: 'Get Started',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'DOB',
          'ADDRESS',
          'TYPE_SKIN_HAIR_CONDITION',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'OTHER_TYPE_CONDITIONS',
          'FIRST_NOTICED_CONDITION',
          'CONDITION_LOCATION_ON_BODY',
          'OFTEN_NOTICE_OF_CONDITION',
          'OTHER_ASSOCIATED_SYMPTOMS',
          'CONDITION_GETS_WORSEN',
          'CONDITIONS_CERTAIN_MEDICATIONS_TYPES',
          'CONDITIONS_CERTAIN_FOODS_TYPES',
          'HAIR_LOSS_ON_YOUR_SCALP',
          'HAIR_LOSS_ON_YOUR_LOCATION',
          'HAIR_LOSS_TYPE_EXPERIENCE',
          'HAIR_LOSS_ONSET',
          'HAIR_LOSS_CONDITION_TYPE',
          'HAIR_LOSS_SCALP_ITCHING_OR_FLAKING',
          'HAIR_LOSS_AFTER_DIET_CHANGED',
          'HAIR_LOSS_LOST_GAINED_WEIGHT',
          'HAIR_LOSS_AFTER_SURGERY',
          'HAIR_LOSS_AFTER_PREGNANCY',
          'HAIR_LOSS_POST_MENOPAUSAL',
          'HAIR_LOSS_ON_HORMONE_TREATMENT',
          'PREVIOUS_TREATMENT',
          'PREVIOUS_TREATMENT_TYPE',
          'PREVIOUS_TREATMENT_HELPFUL',
          'HISTORY_OF_FAMILY',
          'OTHER_TYPE_OF_FAMILY_SKIN_CONDITIONS',
          'HAIR_LOSS_TYPE_OF_PRODUCTS_IN_USE',
          'HAIR_LOSS_AFTER_HAIR_STYLE',
          'HAIR_LOSS_TRIMMING_TIMES',
          'PHOTO_OF_CONDITION',
        ],
      },
    },
  },
  {
    name: 'Behavioral Health',
    type: VISIT_TYPE_CONSTANTS.BEHAVIORAL_VISITS,
    tabs: {
      partOne: {
        name: 'Part I',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'DOB',
          'ADDRESS',
          'DIAGNOSED_WITH_MENTAL_HEALTH',
          'SPECIFIC_DIAGNOSIS_BY_PROVIDER',
          'TYPE_OF_KNOWN_DIAGNOSIS',
          'PERIOD_KNOWING_DIAGNOSIS',
          'BY_WHAT_PROVIDER',
          'ONGOING_TREATMENT',
          'PAST_TREATMENT_TYPE',
          'MEDICATION_PAST_TREATMENT',
          'SIDE_EFFECT_FROM_PAST_MEDICATION',
          'CONTINUE_PAST_MEDICATION',
          'OTHER_PAST_TREATMENT_TYPE',
          'PERIOD_ON_PAST_TREATMENT_TYPE',
          'BREAK_POINT_PART_ONE',
        ],
      },
      partTwo: {
        name: 'Part II',
        questionKeys: [
          'FEELING_ANXIOUS_OR_EDGE',
          'UNABLE_TO_STOP_WORRYING',
          'UNABLE_TO_STOP_WORRYING_TOO_MUCH',
          'TROUBLE_RELAXING',
          'RESTLESS_HARD_TO_SIT',
          'EASILY_ANNOYED_OR_IRRITABLE',
          'FEELING_AFRAID_MIGHT_HAPPEN',
          'LITTLE_INTEREST_IN_THINGS',
          'FEELING_DOWN_DEPRESSED_HOPELESS',
          'TROUBLE_SLEEPING_OR_SLEEPING_TOO_MUCH',
          'FEELING_TIRED_OR_LITTLE_ENERGY',
          'POOR_APPETITE_OR_OVEREATING',
          'FEELING_BAD_OF_YOURSELF',
          'TROUBLE_CONCENTRATING_THINGS',
          'SLOWLY_OR_MOVING_FAST_WITH_THINGS',
          'THOUGHTS_OF_YOUR_DEATH_OR_HURT',
          'BREAK_POINT_PART_TWO',
        ],
      },
      partThree: {
        name: 'Part III',
        questionKeys: [
          'DEPRESSION_ANXIETY_SYMPTOMS_AS_CHILD',
          'HOSPITALIZED_DEPRESSION_ANXIETY_SYMPTOMS',
          'DATE_OF_HOSPITAL_VISIT',
          'PERIOD_TIME_IN_HOSPITAL',
          'DETAILS_OF_HOSPITAL_VISIT',
          'NEW_STRESSORS_CAUSING_MENTAL_HEALTH',
          'NEW_STRESSORS_MORE_INFO',
          'HEARD_VOICES_OR_SEEN_THINGS',
          'USED_NICOTINE_PRODUCT',
          'MORE_DRINKS_WITH_ALCOHOL',
          'WRONG_USED_OF_PRESCRIPTION',
          'USE_OF_MARIJUANA',
          'USE_ANY_OTHER_STREET_DRUGS',
          'FAMILY_HISTORY_OF_MENTAL_HEALTH_CONDITIONS',
          'WHICH_MENTAL_CONDITION',
          'TYPE_OF_MENTAL_HEALTH_TREATMENT_INTERESTED',
          'SPECIFIC_MEDICATION_TO_KNOW',
          'LIST_MEDICATIONS_INTERESTED_IN',
          'TAKING_HERBAL_OR_VITAMINS_SUPPLEMENTS',
          'DETAILS_OF_TAKING_SUPPLEMENTS',
          'TAKING_OVER_THE_COUNTER_MEDICATIONS',
          'DETAILS_OF_TAKING_COUNTER_MEDICATIONS',
          'HAVE_ALLERGIES_DRUGS',
          'DETAILS_OF_ALLERGIES',
          'ADDITIONAL_INFORMATION',
          'DETAILS_ON_ADDITIONAL_INFORMATION',
        ],
      },
    },
  },
  {
    name: 'Genital Herpes',
    type: VISIT_TYPE_CONSTANTS.GENITAL_HERPES_VISITS,
    tabs: {
      patientInfo: {
        name: 'Patient Info',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'AGE_OLD',
          'ADDRESS',
          'ASSIGNED_SEX_AT_BIRTH',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'PREVIOUSLY_GENITAL_HERPES',
          'TESTED_POSITIVE_FOR_HIV',
          'SEXUALLY_ACTIVE',
          'ENGAGED_IN_ORAL_SEX',
          'TESTED_POSITIVE_STI',
          'TYPE_OF_STI',
          'HISTORY_OF_COLD_SORES',
          'HAVE_GENITAL_ULCERS',
          'PAINFUL_GENITAL_ULCERS',
          'PAIN_CONDITION',
          'ITCHY_GENITAL_ULCERS',
          'HAVE_TENDER_LYMPH_NODES',
          'HAVE_FEVER',
          'CURRENTLY_TIRED',
          'MUSCLE_ACHES',
          'EXPERIENCING_HEADACHES',
          'DIFFICULT_TO_URINATE',
          'PAIN_IN_URINE',
          'VAGINAL_DISCHARGE',
          'PENILE_DISCHARGE',
          'PAIN_AT_PELVIC_AREA',
        ],
      },
    },
  },
  {
    name: 'Cold Sores',
    type: VISIT_TYPE_CONSTANTS.COLD_SORES_VISITS,
    tabs: {
      getStarted: {
        name: 'Get Started',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'AGE_OLD',
          'ADDRESS',
          'NOTICED_COLD_SORES',
        ],
      },
      symptoms: {
        name: 'Symptoms',
        questionKeys: [
          'CONDITION_OF_COLD_SORES',
          'PREVIOUS_COLD_SORES',
          'COLD_SORE_OUTBREAK',
          'DIAGNOSED_STI',
          'WHAT_WAS_THE_DIAGNOSIS',
          'TREATED_FOR_STI',
          'EXPERIENCING_SYMPTOMS',
          'OTHER_INFO',
          'PHOTO_OF_CONDITION',
        ],
      },
    },
  },
  {
    name: 'Rx Refills',
    type: VISIT_TYPE_CONSTANTS.RX_REFILLS,
    tabs: {
      mailingAddress: {
        name: 'Mailing Address',
        questionKeys: [
          'VISIT_FOR_DEPENDENT',
          'NAME_OF_DEPENDENT',
          'RELATION_TO_DEPENDENT',
          'DOB',
          'ADDRESS',
        ],
      },
      requestInfo: {
        name: 'Request Info',
        questionKeys: [
          'CONFIRM_ALLERGIES',
          'CURRENT_MEDICATIONS',
          'OTHER_CURRENT_MEDICATIONS',
          'STOPPED_MEDICATIONS',
          'ONGOING_MEDICATIONS',
          'MEDICAL_HISTORY_CHANGES',
          'ABOUT_HEALTH_CHANGES',
          'MEDICATION_PRESCRIBED_BY',
          'ANOTHER_PROVIDER_NAME',
          'MEDICATION_NAME',
          'MEDICATION_DOSAGE',
          'MEDICATION_REASON',
          'MEDICATION_DURATION',
          'MEDICATION_PILLS_DAYS_LEFT',
          'PHOTO_OF_MEDICATION',
          'MEDICATION_MAILING_DETAILS',
        ],
      },
    },
  },
];

export const visitIconsAndColors: Record<
  string,
  {
    icon: string;
    color: string;
    bgColor: string;
  }
> = {
  'birth-control': {
    icon: 'iconChatCardBirthControlPill',
    color: '#2FAEF3',
    bgColor: 'rgb(229, 246, 255)',
  },
  sick: {
    icon: 'iconChatCardSickVisitPill',
    color: '#FF8C42',
    bgColor: '#FFE6D6',
  },
  uti: {
    icon: 'iconChatCardUTI',
    color: '#F4ACB8',
    bgColor: '#FFE1E6',
  },
  'std-diagnosis': {
    icon: 'iconChatCardSexualHealth',
    color: '#FFC26F',
    // bgColor: '#FFE0AF',
    bgColor: '#FFF6E7',
  },
  'std-test': {
    icon: 'iconChatCardSexualHealth',
    color: '#FFC26F',
    bgColor: '#FFF6E7',
  },
  'cold-sores': {
    icon: 'iconChatCardSexualHealth',
    color: '#FFC26F',
    bgColor: '#FFF6E7',
  },
  'genital-herpes': {
    icon: 'iconChatCardSexualHealth',
    color: '#FFC26F',
    bgColor: '#FFF6E7',
  },
  'mental-health': {
    icon: 'iconChatCardMentalHealthTreatment',
    color: '#d8ae39',
    bgColor: 'rgb(255, 245, 209)',
  },
  'hair-skin': {
    icon: 'iconChatCardHairSkin',
    color: '#68C5A3',
    bgColor: 'rgb(222, 255, 243)',
  },
  'vaginal-infections': {
    icon: 'iconChatCardVaginalInfections',
    color: '#B760FF',
    bgColor: '#F4E7FF',
  },
  'menstrual-irregularities': {
    icon: 'iconChatCardMenstrualIrregularities',
    color: '#FFAE2D',
    bgColor: 'rgb(255, 246, 231)',
  },
  'erectile-dysfunction': {
    icon: 'iconChatCardErectileDysfunction',
    color: '#EAA37D',
    bgColor: 'rgb(255, 238, 227)',
  },
  'rx-refills': {
    icon: 'iconChatCardRxRefill',
    color: '#2FAEF3',
    bgColor: 'rgb(229, 246, 255)',
  },
};

export function fetchQuestions(run: (promise: Promise<unknown>) => void, typeOfVisit: string) {
  const fetchingQuestions = apigateway.get(`/questionnaires/${typeOfVisit}/questions`);
  run(fetchingQuestions);
}

export function fetchQuesAnswers(
  run: (promise: Promise<unknown>) => void,
  params?: Record<string, string>,
) {
  const paramsT = pickBy(value => Boolean(value && !isEmpty(value)), params);
  const fetchingQuesAnswers = apigateway.get(`/questionnaires`, paramsT);
  run(fetchingQuesAnswers);
}

export function getQuestionsAndLatestAnswer(
  run: (promise: Promise<unknown>) => void,
  typeOfVisit: string,
) {
  const fetching = Promise.all([
    apigateway.get(`/questionnaires/${typeOfVisit}/questions`),
    apigateway.get(`/questionnaires`),
  ]);

  run(fetching);
}

export function fetchLabTests(
  run: (promise: Promise<unknown>) => void,
  params?: Record<string, unknown>,
) {
  const fetchingLabTests = apigateway.get(`/lab-tests`, params || {});

  run(fetchingLabTests);
}

export function fetchClinics(run: (promise: Promise<unknown>) => void) {
  const fetchingClinics = apigateway.get(`/available-clinics`);
  run(fetchingClinics);
}

export function fetchListings(
  run: (promise: Promise<unknown>) => void,
  params?: Record<string, string | number>,
  listingsType = 'providers',
) {
  if (params) {
    Object.keys(params).forEach(paramKey => {
      if (!params[paramKey]) {
        // eslint-disable-next-line no-param-reassign
        delete params[paramKey];
      }
    });
  }

  // const fetchingProviders = apigateway.get(`/integrations/ribbon/${listingsType}`, params || {});

  const fetchingProviders = getListingsData(params || {}, listingsType);

  run(fetchingProviders);
}

export async function getListingsData(
  params: Record<string, string | number>,
  listingsType: string,
) {
  if (!params?.state) {
    return {};
  }

  const signedUrgentCareURL = await readAWSS3File(
    'urgent-cares',
    `https://${env('RIVER_AWS_BUCKET_NAME')}/public/urgent-cares/${(
      params.state as string
    ).toLowerCase()}.xlsx`,
  );

  const response = await axios.get(signedUrgentCareURL, { responseType: 'arraybuffer' });

  // Parse the Excel file
  const workbook = XLSX.read(response.data, { type: 'array' });

  const findCity =
    Object.keys(workbook.Sheets).find(city =>
      city.toLowerCase().trim().includes(`${params?.city}`.toLowerCase().trim()),
    ) || ''; // The worksheets are group into cities

  const worksheet = workbook.Sheets[findCity.trim()];

  const jsonData = XLSX.utils.sheet_to_json(worksheet);

  const clinicListings = jsonData?.map((dataT: any) => {
    let addressT = dataT?.Address;
    let phoneNumbersT: Array<{ phone: string; details: string }> = [];

    if (!addressT) {
      addressT = `${dataT?.Street ? `${dataT.Street}. ` : ''}${
        dataT?.City ? `${dataT.City}, ` : ''
      }${dataT?.State || ''} ${dataT['Zip Code'] || ''}`;
    }

    if (dataT?.['Phone Number']) {
      phoneNumbersT = [{ phone: dataT?.['Phone Number'], details: 'primary' }];
    }

    return {
      name: dataT['Provider Name'],
      address: addressT,
      googleMapsLink: `https://www.google.com/maps?q=${encodeURIComponent(addressT)}`,
      phoneNumbers: phoneNumbersT,
    };
  });

  const listingsData = listingsType === 'providers' ? [] : clinicListings;

  return {
    data: listingsData,
    parameters: {
      pageSize: 1,
      totalCount: listingsData.length,
      page: 1,
    },
  };
}

export function saveQuestionAnswers(
  questions: Question[],
  answers: Answer[],
  typeOfVisit: string,
  run: (promise: Promise<unknown>) => void,
) {
  let sortedProvidedAnswers = answers;

  if (questions.length > 0) {
    sortedProvidedAnswers = questions.map(question => {
      const quesAns = answers.find(questAns => questAns.questionKey === question.id)?.answers;
      return { questionKey: question.id, answers: quesAns || ['-'] };
    });
  }

  const savingAnswers = apigateway.post(`/questionnaire/${typeOfVisit}`, sortedProvidedAnswers);

  run(savingAnswers);
}

export async function deleteCareServicesCount(
  userId: string,
  payload: {
    link: string;
    type: string;
    preRegistrationCode: string;
  },
) {
  await apigateway.delete(`/user/${userId}/visit`, payload);
  return getCareServicesCount(userId);
}

export async function getCareServicesCount(userId: string) {
  const { stdVisitCount, visitCount } = (await apigateway.get(`/user/${userId}/member`)) as any;
  return { stdVisitCount, visitCount };
}

export function findQuestionsTab(questions: Question[], questionTabs: any) {
  const questionIds = pluck('id', questions);

  const tabWithQuestions = Object.entries(questionTabs) as [string, any];

  const getQuestionTab = tabWithQuestions.filter(
    (tabWithQuestion: [string, { name: string; questionKeys: string[] }]) => {
      const questionsKeys = tabWithQuestion[1].questionKeys;
      return questionIds.every(id => questionsKeys.includes(id));
    },
  );

  return getQuestionTab.length > 0 ? getQuestionTab[0][0] : '';
}

export async function listenToLatestVisit() {
  const visits = (await apigateway.get(`/questionnaires`)) as QuestionAnswers[];

  if (!visits) return null;

  const visit = sortBy(prop('updatedAt'), visits)[visits.length - 1];

  const visitType = visitTypes.find(
    fndVisitType => fndVisitType.type === visit.type.toLocaleUpperCase(),
  ) as Visit;

  return { ...visit, name: visitType.name } as QuestionAnswers;
}

export function extractSubQuestions(questions: Question[]): Question[] {
  const followups: Question[] = [];
  const stack: Question[] = [...questions];

  while (stack.length > 0) {
    const question = stack.pop();
    if (question && question.options) {
      question.options.forEach(option => {
        if (option.followups) {
          followups.push(...option.followups);
          stack.push(...option.followups);
        }
      });
    }
  }

  return uniqBy(prop('id'), followups);
}

export function flattenQuestions(questions: Question[]): Question[] {
  const flattenedList: Question[] = [];
  const stack: Question[] = [...questions];

  while (stack.length > 0) {
    const question = stack.shift() as Question;
    flattenedList.push(question);

    if (question.options && question.options.length > 0) {
      for (let i = question.options.length - 1; i >= 0; i -= 1) {
        const option = question.options[i];
        if (option.followups && option.followups.length > 0) {
          stack.unshift(...option.followups.reverse());
        }
      }
    }
  }

  return flattenedList;
}

export function getAllOptQuestionsKeys(question?: Question | Question[]) {
  const optQuestionsSkipToKeys: string[] = [];

  if (!question) return optQuestionsSkipToKeys;

  return optQuestionsSkipToKeys
    ? uniq(optQuestionsSkipToKeys).filter(fndOpt => Boolean(fndOpt))
    : [];
}

export function sortAnswersByQuestions(questions: Question[], answers: Answer[]) {
  return sortBy(prop('priority'), questions)
    .map(fndQuestion => {
      const quesAns = answers.find(
        fndQuesAns => fndQuesAns.questionKey === fndQuestion.id,
      )?.answers;

      return { questionKey: fndQuestion.id, answers: quesAns };
    })
    .filter(fndAnswer => fndAnswer.answers && fndAnswer.answers.length > 0);
}

export function isForASinglePage(questions: Question[]) {
  return questions?.some((question, index) => {
    const isMultipleChoice =
      (question.type === 'MULTIPLE-CHOICE' ||
        question.type === 'BINARY' ||
        question.type === 'BINARY-SELECT' ||
        question.type === 'CUSTOM-BINARY') &&
      question?.options &&
      question?.options?.length > 3;
    const isOpenImage = question.type === 'OPEN-IMAGE';
    const isOpenFile = question.type === 'OPEN-FILE' || question.type === 'OPEN-FILES';
    const isAddress = question.type === 'OPEN-ADDRESS';
    const isBreakPoint = question.type === 'BREAK-POINT';
    const isBloodPressure = question.type === 'OPEN-BLOOD-PRESSURE';

    // check if first question has skipTo
    // Does any of the questions depends on first question on the page with skipTo
    const nxtQuestion = questions?.[index + 1];

    const dependsOnFirstQuesOnThePage = nxtQuestion
      ? getAllOptQuestionsKeys(question).includes(nxtQuestion.id)
      : false;

    return (
      isMultipleChoice ||
      isOpenImage ||
      isAddress ||
      isOpenFile ||
      isBreakPoint ||
      isBloodPressure ||
      dependsOnFirstQuesOnThePage
    );
  });
}

export function takeOutQuestionsByHiddenAnswers(
  questions: Question[],
  answers: Answer[],
): Question[] {
  const findHiddenAnswers = answers.filter(
    answer => answer.answers.length === 1 && answer.answers[0] === '-',
  );

  const answerQuestionKeys = pluck('questionKey', findHiddenAnswers);

  if (answerQuestionKeys.length > 0) {
    const previousQuestions = questions.filter(
      question => !answerQuestionKeys.includes(question.id),
    );

    return previousQuestions;
  }

  return questions;
}

export function getQuestionsByHiddenAnswers(questions: Question[], answers: Answer[]): Question[] {
  const findHiddenAnswers = answers.filter(
    answer => answer.answers.length === 1 && answer.answers[0] === '-',
  );

  const answerQuestionKeys = pluck('questionKey', findHiddenAnswers);

  if (answerQuestionKeys.length > 0) {
    const previousQuestions = questions.filter(question =>
      answerQuestionKeys.includes(question.id),
    );

    return previousQuestions;
  }

  return [];
}

export const mapQuestionsToAnswer: Record<
  string,
  (
    questions: Question[],
    answer: Answer,
    answerToDisplay?: string,
  ) => {
    quesOption: string;
    ansOption: string;
  }
> = {
  'birth-control': mapBirthControlQuestionsToAnswer,
  sick: mapSickQuestionsToAnswer,
  uti: mapUTIQuestionsToAnswer,
  'std-diagnosis': mapStdDiagnosisQuestionsToAnswer,
  'std-test': mapStdTestQuestionsToAnswer,
  'cold-sores': mapColdSoresQuestionsToAnswer,
  'genital-herpes': mapGenitalHerpesQuestionsToAnswer,
  'mental-health': mapBehavioralQuestionsToAnswer,
  'hair-skin': mapHairSkinQuestionsToAnswer,
  'vaginal-infections': mapVaginalInfectionQuestionsToAnswer,
  'menstrual-irregularities': mapMenstrualIrregularitiesQuestionsToAnswer,
  'erectile-dysfunction': mapErectileDysfunctionQuestionsToAnswer,
  'rx-refills': mapRxRefillsQuestionsToAnswer,
};

function mapBirthControlQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'BLOOD_PRESSURE_READING') {
    quesOption = 'What is your blood pressure reading? (Systolic and Diastolic)';
  }

  if (question.id === 'ADDRESS') {
    // quesOption = 'Address';
    // ansOption =  formatToFullAddress(answer?.answers);
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question.id === 'FULL_NAME') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'DATE_OF_BIRTH') {
    quesOption = '';
    ansOption = '';
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  if (question.id === 'MEDICATION_MAILING_DETAILS') {
    ansOption = ansOption.replace('**{insert mailing address}**', '');
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapSickQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  const quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'ADDRESS') {
    // quesOption = 'Address';
    // ansOption = formatToFullAddress(answer?.answers);
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapUTIQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'AGE_OLD') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'GENDER_TYPE') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapStdDiagnosisQuestionsToAnswer(
  questions: Question[],
  answer: Answer,
  answerToDisplay?: string,
) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'TYPE_OF_MEDICATION') {
    quesOption = 'Medications';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  if (question.id === 'DIAGNOSIS' && ansOption === 'Something else') {
    quesOption = '';
    ansOption = '';
  }

  return {
    quesOption: quesOption
      .replace('**{insert selected STD}**', answerToDisplay || 'this STI')
      .replace('[insert selected STD]', answerToDisplay || 'this STI'),
    ansOption,
  };
}

function mapStdTestQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'PARTNER_DIAGNOSIS' && ansOption === 'SOMETHING_ELSE') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'OTHER_DIAGNOSIS' && ansOption !== '-') {
    quesOption = 'What was your sexual partner(s) diagnosed with?';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapGenitalHerpesQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'AGE_OLD') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'ASSIGNED_SEX_AT_BIRTH') {
    quesOption = 'What was your assigned sex at birth?';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapColdSoresQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'AGE_OLD') {
    quesOption = '';
    ansOption = '';
  }

  if (question.id === 'PHOTO_OF_CONDITION') {
    quesOption = 'Photo';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapBehavioralQuestionsToAnswer(
  questions: Question[],
  answer: Answer,
  answerToDisplay?: string,
) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let responseQuesToDisplay = '';
  let optionQuesToDisplay = '';

  if (answerToDisplay === 'Anxiety' || answerToDisplay?.includes('Anxious')) {
    responseQuesToDisplay = 'feeling anxious';
    optionQuesToDisplay = 'Anxiety';
  }

  if (answerToDisplay === 'Depression' || answerToDisplay?.includes('Depressed')) {
    responseQuesToDisplay = 'feeling depressed';
    optionQuesToDisplay = 'Depression';
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'OTHER_DIAGNOSIS' && ansOption !== '-') {
    quesOption = 'What was your sexual partner(s) diagnosed with?';
  }

  if (question.id === 'DETAILS_OF_TAKING_COUNTER_MEDICATIONS') {
    quesOption = 'Medications';
  }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  if (question.id === 'TYPE_BEHAVIORAL_HEALTH') {
    if (ansOption === 'Anxiety' || answerToDisplay?.includes('Anxious')) {
      ansOption = 'Anxious';
    }

    if (ansOption === 'Depression' || answerToDisplay?.includes('Depressed')) {
      ansOption = 'Depressed';
    }

    quesOption = 'How are you feeling today?';
  }

  return {
    quesOption: quesOption.replace('[feeling symptoms]', responseQuesToDisplay),
    ansOption: ansOption.replace('[feeling symptoms]', optionQuesToDisplay),
  };
}

function mapHairSkinQuestionsToAnswer(
  questions: Question[],
  answer: Answer,
  answerToDisplay?: string,
) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let conditionTypeAnswerToDisplay = answerToDisplay;

  if (answerToDisplay === 'Something Else') {
    conditionTypeAnswerToDisplay = 'condition';
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question.id === 'PHOTO_OF_CONDITION') {
    quesOption = 'Photo';
    if (ansOption === '-') {
      ansOption = '';
    }
  }

  if (question.id === 'TYPE_SKIN_HAIR_CONDITION') {
    quesOption = 'What Hair or Skin condition are you experiencing?';

    if (ansOption === 'SOMETHING_ELSE') {
      quesOption = '';
      ansOption = '';
    }
  }

  if (question.id === 'EXPERIENCING_ANY_OTHER_RELATED_ISSUES') {
    quesOption = 'What Hair or Skin condition are you experiencing?';
  }

  if (question.id === 'PREVIOUS_TREATMENT_TYPE') {
    quesOption = 'Medications';
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption: quesOption.replace(
      '**{insert selected condition}**',
      conditionTypeAnswerToDisplay || '',
    ),
    ansOption,
  };
}

function mapVaginalInfectionQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question.id === 'AGE_OLD') {
    quesOption = '';
    ansOption = '';
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  if (question.id === 'DETAILS_OF_CURRENT_MEDICATIONS') {
    quesOption = 'Medications';

    if (ansOption === 'No') {
      ansOption = 'None';
    }
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapMenstrualIrregularitiesQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  const quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapErectileDysfunctionQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question.id === 'AGE_OLD') {
    quesOption = '';
    ansOption = '';
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  return {
    quesOption,
    ansOption,
  };
}

function mapRxRefillsQuestionsToAnswer(questions: Question[], answer: Answer) {
  const question = questions.find(ques => ques.id === answer.questionKey);

  if (!question) {
    return {
      quesOption: '',
      ansOption: '',
    };
  }

  if (!answer?.answers) {
    return {
      quesOption: question?.text || '',
      ansOption: '',
    };
  }

  let quesOption = question?.text || '';

  let ansOption = answer?.answers[0];

  // if (question.id === 'ADDRESS') {
  //   quesOption = 'Mailing Address';
  //   ansOption = formatToFullAddress(answer?.answers);
  // }

  if (question.id === 'ADDRESS') {
    ansOption =
      US_STATES.find(state => state.value === ansOption)?.label ||
      formatToFullAddress(answer?.answers);
  }

  if (question.id === 'PHOTO_OF_MEDICATION') {
    quesOption = 'Photo';
  }

  if (question?.options && question?.options.length > 0) {
    const listOfOptions = pluck(
      'text',
      question?.options.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  // Support Legacy Questions
  if (question?.children && question?.children.length > 0) {
    const listOfOptions = pluck(
      'option',
      question?.children.filter(child => answer.answers.includes(child.id)),
    );

    ansOption =
      listOfOptions.length === 0
        ? answer?.answers.join(', ')
        : formatArrayIntoSentence(listOfOptions);
  }

  if (
    [
      'MEDICATION_PRESCRIBED_BY',
      'ANOTHER_PROVIDER_NAME',
      'MEDICATION_NAME',
      'MEDICATION_DOSAGE',
      'MEDICATION_REASON',
      'MEDICATION_DURATION',
      'MEDICATION_PILLS_DAYS_LEFT',
      'PHOTO_OF_MEDICATION',
    ].includes(question.id)
  ) {
    const medAnsOption = answer?.answers
      ?.map(findAnswer => {
        let getAnsOption = { [question.id]: findAnswer === 'NONE' ? '' : findAnswer };

        if (question?.options && question?.options.length > 0) {
          const listOfOptions = pluck(
            'text',
            question?.options.filter(child => findAnswer === child.id),
          );

          getAnsOption = {
            [question.id]:
              listOfOptions.length === 0
                ? answer?.answers.join(', ')
                : formatArrayIntoSentence(listOfOptions),
          };
        }

        return getAnsOption;
      })
      .filter(findAnswer => {
        return !equals(findAnswer, { PHOTO_OF_MEDICATION: 'NONE' });
      });
    ansOption = JSON.stringify(medAnsOption);
  }

  if (question.id === 'MEDICATION_MAILING_DETAILS') {
    ansOption = ansOption.replace('**{insert mailing address}**', '');
  }

  return {
    quesOption,
    ansOption,
  };
}

export const getRelatedAnswerToDisplay = (questions: Question[], answers: Answer[]) => {
  const findAnswer =
    answers.find(ans =>
      [
        'DIAGNOSIS',
        'OTHER_DIAGNOSIS',
        'TYPE_BEHAVIORAL_HEALTH',
        'TYPE_SKIN_HAIR_CONDITION',
      ].includes(ans.questionKey),
    )?.answers[0] || '';

  const findQuestion = questions.find(ques =>
    ['DIAGNOSIS', 'OTHER_DIAGNOSIS', 'TYPE_BEHAVIORAL_HEALTH', 'TYPE_SKIN_HAIR_CONDITION'].includes(
      ques.id,
    ),
  );

  if (findQuestion?.options && findQuestion?.options.length > 0) {
    const answerToDisplay = findQuestion.options
      ?.filter(child => child.id === findAnswer)
      .map(ques => ques.text) as string[];

    if (answerToDisplay && answerToDisplay.length > 0) {
      return formatArrayIntoSentence(answerToDisplay);
    }
  }

  // Support Legacy Questions
  if (findQuestion?.children && findQuestion?.children.length > 0) {
    const answerToDisplay = findQuestion.children
      ?.filter(child => child.id === findAnswer)
      .map(ques => ques.option) as string[];

    if (answerToDisplay && answerToDisplay.length > 0) {
      return formatArrayIntoSentence(answerToDisplay);
    }
  }

  return findAnswer;
};

export const formatToFullAddress = (address: string[]) => {
  if (address.length === 0) {
    return 'No Location details found';
  }

  if (address[1] && address[2] && address[3] && address[4]) {
    return `${address[0] ? `${address[0]}.` : ''} ${address[2] ? `${address[2]},` : ''} ${
      address[3] || ''
    } ${address[1] || ''}`;
  }

  /* Street Address. City, State Apartment */
  /* 123 Sunnylane Ave. Los Angeles, LA 56902 */
  return `${address[0]}`;
};

export const providerListings: ProviderListing[] = [
  {
    firstName: 'John',
    middleName: 'A.',
    lastName: 'Smith',
    ratingsAvg: 4.8,
    ratingsCount: 210,
    degrees: ['MD'],
    languages: ['English', 'Spanish'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. John Smith',
        providerType: 'General Practitioner',
        boardSpecialty: 'Family Medicine',
        display: 'Family Medicine',
      },
    ],
    locations: [
      {
        confidence: 95,
        address: '123 Elm St, Springfield, IL',
        phoneNumbers: [{ phone: '123-456-7890', details: 'primary' }],
        addressDetails: {
          street: '123 Elm St',
          city: 'Springfield',
          state: 'IL',
          zip: '62704',
        },
      },
    ],
    npi: '1234567890',
  },
  {
    firstName: 'Emily',
    middleName: 'B.',
    lastName: 'Johnson',
    ratingsAvg: 4.6,
    ratingsCount: 180,
    degrees: ['DO', 'MPH'],
    languages: ['English', 'French'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Emily Johnson',
        providerType: 'Pediatrician',
        boardSpecialty: 'Pediatrics',
        display: 'Pediatrics',
      },
    ],
    locations: [
      {
        confidence: 90,
        address: '456 Oak St, Los Angeles, CA',
        phoneNumbers: [{ phone: '321-654-0987', details: 'primary' }],
        addressDetails: {
          street: '456 Oak St',
          city: 'Los Angeles',
          state: 'CA',
          zip: '90001',
        },
      },
    ],
    npi: '9876543210',
  },
  {
    firstName: 'Michael',
    middleName: 'C.',
    lastName: 'Williams',
    ratingsAvg: 4.7,
    ratingsCount: 300,
    degrees: ['MD'],
    languages: ['English'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Michael Williams',
        providerType: 'Cardiologist',
        boardSpecialty: 'Cardiology',
        display: 'Cardiology',
      },
    ],
    locations: [
      {
        confidence: 98,
        address: '789 Pine St, Miami, FL',
        phoneNumbers: [{ phone: '456-789-0123', details: 'primary' }],
        addressDetails: {
          street: '789 Pine St',
          city: 'Miami',
          state: 'FL',
          zip: '33101',
        },
      },
    ],
    npi: '1029384756',
  },
  {
    firstName: 'Sophia',
    middleName: 'D.',
    lastName: 'Brown',
    ratingsAvg: 4.9,
    ratingsCount: 250,
    degrees: ['MD', 'PhD'],
    languages: ['English', 'German'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Sophia Brown',
        providerType: 'Oncologist',
        boardSpecialty: 'Medical Oncology',
        display: 'Oncology',
      },
    ],
    locations: [
      {
        confidence: 97,
        address: '321 Maple St, Boston, MA',
        phoneNumbers: [{ phone: '987-654-3210', details: 'primary' }],
        addressDetails: {
          street: '321 Maple St',
          city: 'Boston',
          state: 'MA',
          zip: '02108',
        },
      },
    ],
    npi: '5647382910',
  },
  {
    firstName: 'Daniel',
    middleName: 'E.',
    lastName: 'Davis',
    ratingsAvg: 4.5,
    ratingsCount: 100,
    degrees: ['MD'],
    languages: ['English', 'Mandarin'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Daniel Davis',
        providerType: 'Neurologist',
        boardSpecialty: 'Neurology',
        display: 'Neurology',
      },
    ],
    locations: [
      {
        confidence: 94,
        address: '789 Cedar St, Seattle, WA',
        phoneNumbers: [{ phone: '654-321-9876', details: 'primary' }],
        addressDetails: {
          street: '789 Cedar St',
          city: 'Seattle',
          state: 'WA',
          zip: '98101',
        },
      },
    ],
    npi: '1827364590',
  },
  {
    firstName: 'Olivia',
    middleName: 'F.',
    lastName: 'Martinez',
    ratingsAvg: 4.6,
    ratingsCount: 220,
    degrees: ['MD'],
    languages: ['English', 'Spanish'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Olivia Martinez',
        providerType: 'Dermatologist',
        boardSpecialty: 'Dermatology',
        display: 'Dermatology',
      },
    ],
    locations: [
      {
        confidence: 92,
        address: '654 Birch St, Austin, TX',
        phoneNumbers: [{ phone: '789-012-3456', details: 'primary' }],
        addressDetails: {
          street: '654 Birch St',
          city: 'Austin',
          state: 'TX',
          zip: '73301',
        },
      },
    ],
    npi: '1928374650',
  },
  {
    firstName: 'James',
    middleName: 'G.',
    lastName: 'Wilson',
    ratingsAvg: 4.4,
    ratingsCount: 150,
    degrees: ['MD'],
    languages: ['English', 'Italian'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. James Wilson',
        providerType: 'Orthopedic Surgeon',
        boardSpecialty: 'Orthopedics',
        display: 'Orthopedic Surgery',
      },
    ],
    locations: [
      {
        confidence: 89,
        address: '876 Willow St, Chicago, IL',
        phoneNumbers: [{ phone: '123-654-9870', details: 'primary' }],
        addressDetails: {
          street: '876 Willow St',
          city: 'Chicago',
          state: 'IL',
          zip: '60601',
        },
      },
    ],
    npi: '7382910465',
  },
  {
    firstName: 'Mia',
    middleName: 'H.',
    lastName: 'Lee',
    ratingsAvg: 4.8,
    ratingsCount: 280,
    degrees: ['MD'],
    languages: ['English', 'Korean'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Mia Lee',
        providerType: 'Endocrinologist',
        boardSpecialty: 'Endocrinology',
        display: 'Endocrinology',
      },
    ],
    locations: [
      {
        confidence: 93,
        address: '321 Oak St, Denver, CO',
        phoneNumbers: [{ phone: '456-987-3210', details: 'secondary' }],
        addressDetails: {
          street: '321 Oak St',
          city: 'Denver',
          state: 'CO',
          zip: '80201',
        },
      },
    ],
    npi: '2938475601',
  },
  {
    firstName: 'William',
    middleName: 'I.',
    lastName: 'Thomas',
    ratingsAvg: 4.7,
    ratingsCount: 190,
    degrees: ['MD'],
    languages: ['English', 'Arabic'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. William Thomas',
        providerType: 'Rheumatologist',
        boardSpecialty: 'Rheumatology',
        display: 'Rheumatology',
      },
    ],
    locations: [
      {
        confidence: 95,
        address: '432 Spruce St, New York, NY',
        phoneNumbers: [{ phone: '789-654-1230', details: 'primary' }],
        addressDetails: {
          street: '432 Spruce St',
          city: 'New York',
          state: 'NY',
          zip: '10001',
        },
      },
    ],
    npi: '8765432109',
  },
  {
    firstName: 'Charlotte',
    middleName: 'J.',
    lastName: 'Garcia',
    ratingsAvg: 4.9,
    ratingsCount: 260,
    degrees: ['MD'],
    languages: ['English', 'Portuguese'],
    specialties: [
      {
        isPrimary: true,
        providerName: 'Dr. Charlotte Garcia',
        providerType: 'Gynecologist',
        boardSpecialty: 'Obstetrics and Gynecology',
        display: 'Obstetrics and Gynecology',
      },
    ],
    locations: [
      {
        confidence: 96,
        address: '654 Cedar St, San Francisco, CA',
        phoneNumbers: [{ phone: '987-012-6543', details: 'primary' }],
        addressDetails: {
          street: '654 Cedar St',
          city: 'San Francisco',
          state: 'CA',
          zip: '94101',
        },
      },
    ],
    npi: '9081726354',
  },
  // Add 10 more providers similarly...
];

export const clinicListings: ClinicListing[] = [
  {
    name: 'Urgent Care of America',
    googleMapsLink: 'https://goo.gl/maps/4S5hF2hJ3TqR2',
    phoneNumbers: [
      { phone: '555-123-4567', details: 'primary' },
      { phone: '555-123-4568', details: 'secondary' },
    ],
    address: '123 Main St, Anytown, CA 12345',
    latitude: 37.7749,
    longitude: -122.4194,
  },
  {
    name: 'MedExpress Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/2hJ3K4L5n6R2',
    phoneNumbers: [
      { phone: '555-901-2345', details: 'primary' },
      { phone: '555-901-2346', details: 'secondary' },
    ],
    address: '456 Elm St, Othertown, NY 67890',
    latitude: 40.7128,
    longitude: -74.006,
  },
  {
    name: 'MinuteClinic',
    googleMapsLink: 'https://goo.gl/maps/1hJ2K3L4m5R3',
    phoneNumbers: [
      { phone: '555-111-2222', details: 'secondary' },
      { phone: '555-111-2223', details: 'primary' },
    ],
    address: '789 Oak St, Thistown, TX 34567',
    latitude: 29.7604,
    longitude: -95.3698,
  },
  {
    name: 'Concentra Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/3k2l1m3n4o5R',
    phoneNumbers: [
      { phone: '555-555-1234', details: 'primary' },
      { phone: '555-555-1235', details: 'secondary' },
    ],
    address: '901 Pine St, Thatcity, FL 32111',
    latitude: 27.9944,
    longitude: -82.3943,
  },
  {
    name: 'AFC Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/4o5p6q7r8s9R',
    phoneNumbers: [
      { phone: '555-666-7890', details: 'secondary' },
      { phone: '555-666-7891', details: 'primary' },
    ],
    address: '234 Main St, Somecity, IL 60601',
    latitude: 41.8781,
    longitude: -87.6298,
  },
  {
    name: "St. Michael's Urgent Care",
    googleMapsLink: 'https://goo.gl/maps/2p3q4r5t6y7R',
    phoneNumbers: [
      { phone: '555-777-8888', details: 'primary' },
      { phone: '555-777-8889', details: 'secondary' },
    ],
    address: '345 1st Ave, Newcity, NJ 07001',
    latitude: 40.7359,
    longitude: -74.1724,
  },
  {
    name: 'FastMed Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/1q2w3e4r5t6R',
    phoneNumbers: [
      { phone: '555-999-0000', details: 'secondary' },
      { phone: '555-999-0001', details: 'primary' },
    ],
    address: '567 2nd St, Anothertown, AZ 85001',
    latitude: 33.4484,
    longitude: -112.0739,
  },
  {
    name: 'Urgent Care Plus',
    googleMapsLink: 'https://goo.gl/maps/3k4l5m6n7o8R',
    phoneNumbers: [
      { phone: '555-222-3333', details: 'primary' },
      { phone: '555-222-3334', details: 'secondary' },
    ],
    address: '678 3rd Ave, Thatplace, GA 30301',
    latitude: 33.7489,
    longitude: -84.3879,
  },
  {
    name: 'NextCare Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/4o5p6q7r8s9R',
    phoneNumbers: [
      { phone: '555-444-5555', details: 'secondary' },
      { phone: '555-444-5556', details: 'primary' },
    ],
    address: '789 4th St, Someplace, CO 80201',
    latitude: 39.7392,
    longitude: -104.9903,
  },
  {
    name: 'MD Now Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/2p3q4r5t6y7R',
    phoneNumbers: [
      { phone: '555-888-9999', details: 'primary' },
      { phone: '555-888-9998', details: 'secondary' },
    ],
    address: '901 5th Ave, Thisplace, MI 48201',
    latitude: 42.3314,
    longitude: -83.0458,
  },
  {
    name: 'Physicians Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/1hJ2K3L4m5R3',
    phoneNumbers: [
      { phone: '555-666-7777', details: 'primary' },
      { phone: '555-666-7778', details: 'secondary' },
    ],
    address: '234 6th St, Thatcity, OH 43201',
    latitude: 39.9623,
    longitude: -83.0007,
  },
  {
    name: 'Urgent Care of America',
    googleMapsLink: 'https://goo.gl/maps/4S5hF2hJ3TqR2',
    phoneNumbers: [
      { phone: '555-555-5555', details: 'primary' },
      { phone: '555-555-5556', details: 'secondary' },
    ],
    address: '345 7th St, Somecity, IN 46201',
    latitude: 39.7684,
    longitude: -86.1581,
  },
  {
    name: 'MedExpress Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/2hJ3K4L5n6R2',
    phoneNumbers: [
      { phone: '555-999-8888', details: 'primary' },
      { phone: '555-999-8889', details: 'secondary' },
    ],
    address: '456 8th St, Othertown, KY 40201',
    latitude: 38.2542,
    longitude: -85.7586,
  },
  {
    name: 'MinuteClinic',
    googleMapsLink: 'https://goo.gl/maps/1hJ2K3L4m5R3',
    phoneNumbers: [
      { phone: '555-111-1111', details: 'primary' },
      { phone: '555-111-1112', details: 'secondary' },
    ],
    address: '567 9th St, Thistown, LA 70801',
    latitude: 30.4583,
    longitude: -91.1404,
  },
  {
    name: 'Concentra Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/3k2l1m3n4o5R',
    phoneNumbers: [
      { phone: '555-222-2222', details: 'primary' },
      { phone: '555-222-2223', details: 'secondary' },
    ],
    address: '678 10th St, Thatcity, MS 39201',
    latitude: 32.3207,
    longitude: -90.2088,
  },
  {
    name: 'AFC Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/4o5p6q7r8s9R',
    phoneNumbers: [
      { phone: '555-333-3333', details: 'primary' },
      { phone: '555-333-3334', details: 'secondary' },
    ],
    address: '789 11th St, Somecity, MO 63101',
    latitude: 38.6273,
    longitude: -90.1979,
  },
  {
    name: "St. Michael's Urgent Care",
    googleMapsLink: 'https://goo.gl/maps/2p3q4r5t6y7R',
    phoneNumbers: [
      { phone: '555-444-4444', details: 'primary' },
      { phone: '555-444-4445', details: 'secondary' },
    ],
    address: '901 12th St, Newcity, NC 27601',
    latitude: 35.7676,
    longitude: -78.6382,
  },
  {
    name: 'FastMed Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/1q2w3e4r5t6R',
    phoneNumbers: [
      { phone: '555-777-7777', details: 'primary' },
      { phone: '555-777-7778', details: 'secondary' },
    ],
    address: '234 13th St, Anothertown, OK 73101',
    latitude: 35.4676,
    longitude: -97.5164,
  },
  {
    name: 'Urgent Care Plus',
    googleMapsLink: 'https://goo.gl/maps/3k4l5m6n7o8R',
    phoneNumbers: [
      { phone: '555-888-8888', details: 'primary' },
      { phone: '555-888-8889', details: 'secondary' },
    ],
    address: '345 14th St, Thatplace, OR 97201',
    latitude: 45.5207,
    longitude: -122.6744,
  },
  {
    name: 'NextCare Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/4o5p6q7r8s9R',
    phoneNumbers: [
      { phone: '555-999-9999', details: 'primary' },
      { phone: '555-999-9998', details: 'secondary' },
    ],
    address: '456 15th St, Someplace, PA 19101',
    latitude: 39.9523,
    longitude: -75.1631,
  },
  {
    name: 'MD Now Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/2p3q4r5t6y7R',
    phoneNumbers: [
      { phone: '555-666-6666', details: 'primary' },
      { phone: '555-666-6667', details: 'secondary' },
    ],
    address: '567 16th St, Thisplace, RI 02901',
    latitude: 41.8249,
    longitude: -71.4144,
  },
  {
    name: 'Physicians Urgent Care',
    googleMapsLink: 'https://goo.gl/maps/1hJ2K3L4m5R3',
    phoneNumbers: [
      { phone: '555-777-8888', details: 'primary' },
      { phone: '555-777-8889', details: 'secondary' },
    ],
    address: '678 17th St, Thatcity, SC 29201',
    latitude: 34.0007,
    longitude: -81.0348,
  },
  {
    name: 'Urgent Care of America',
    googleMapsLink: 'https://goo.gl/maps/4S5hF2hJ3TqR2',
    phoneNumbers: [
      { phone: '555-888-9999', details: 'primary' },
      { phone: '555-888-9998', details: 'secondary' },
    ],
    address: '789 18th St, Somecity, TN 37201',
    latitude: 36.1627,
    longitude: -86.7816,
  },
];
