/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
import moment from 'moment';
import * as React from 'react';
import DOMPurify from 'dompurify';
import { Graphic, Icon, Image } from '..';
import { visitIconsAndColors } from '../../context/visitContext';
import { Message, MessageContentType } from '../../pages/Messages/Messages';
import { paths } from '../../routes';
import formatChatTime from '../../utils/formatChatTime';
import isValidURL from '../../utils/isValidURL';
import {
  FILE_PATTERN_KEY_REGEX,
  getFileExtension,
  getFileNameFromURL,
  getFileType,
} from '../../utils/getFileProperties';
import { IconSprite } from '../Icon/Icon';

type ChatConvoProps = {
  messages: Record<string, Message[]>;
  lastMessageRef: React.RefObject<HTMLDivElement>;
  // eslint-disable-next-line react/require-default-props
  onResponse?: () => void;
};

function ChatConvo({ messages: groupedMessages, lastMessageRef, onResponse }: ChatConvoProps) {
  const dates = Object.keys(groupedMessages);

  return (
    <>
      {dates.map((date, dateIndex) => {
        const messages = groupedMessages[date];
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={dateIndex}>
            <div id="groupedTimestamp" className="text-center relative h-fit mb-8">
              <span
                className="inline-block font-poppins-medium text-[#717171] text-[11px] border-[1px] bottom-[#e1e1e1]
              rounded-3xl py-2 px-6 leading-[1.4] uppercase"
              >
                {date}
              </span>
            </div>
            <div className="flex flex-col relative">
              {messages.map((message, messageIndex) => {
                const assignLastMessageRef = () =>
                  dateIndex === dates.length - 1 && messageIndex === messages.length - 1
                    ? lastMessageRef
                    : null;

                return (
                  <React.Fragment key={message.id}>
                    {message.type === 'TEXT' ? (
                      <div
                        ref={assignLastMessageRef()}
                        id={message.id}
                        className={`max-w-[90%] tablet:max-w-[80%] laptop:max-w-[50%] min-w-[100px] mb-3 ${
                          message.isSender ? 'chat-sender self-end' : 'chat-receiver self-start'
                        } `}
                      >
                        <TextBubble message={message} />
                        <button
                          onClick={onResponse}
                          type="button"
                          className="float-right text-river-blue text-xs font-semibold"
                        >
                          Respond
                        </button>
                        {/* <span className="text-[11px] text-[#b6b6b6] float-right uppercase">
                          {message?.tempMessageId ? (
                            <Icon id="clock" className="w-[13.5px] h-[13.5px]" />
                          ) : (
                            formatChatTime(message.createdAt)
                          )}
                        </span> */}
                      </div>
                    ) : (
                      <div
                        ref={assignLastMessageRef()}
                        id={message.id}
                        className={`max-w-[90%] tablet:max-w-[80%] laptop:max-w-[50%] w-fit mb-3 ${
                          message.isSender ? 'chat-sender self-end' : 'chat-receiver self-start'
                        } `}
                      >
                        {message.type === 'LAB-REPORT' || message.type === 'QUESTIONNAIRE' ? (
                          <QuestionnaireBubble message={message} />
                        ) : message.type === 'VIDEO-CALL' ? (
                          <VideoCallBubble message={message} />
                        ) : message.type === 'IMAGE' ||
                          message.type === 'VIDEO' ||
                          message.type === 'FILE' ? (
                          <FileBubble message={message} />
                        ) : (
                          <span>NO CONTENT</span>
                        )}
                        <span className="text-[11px] text-[#b6b6b6] float-right uppercase">
                          {message?.tempMessageId ? (
                            <Icon id="clock" className="w-[13.5px] h-[13.5px]" />
                          ) : (
                            formatChatTime(message.createdAt)
                          )}
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

ChatConvo.defaultProps = {};

export default ChatConvo;

function TextBubble({ message }: { message: Message }) {
  let { content } = message;

  let fileURLs: string[] = [];

  const matchedContent = content.match(FILE_PATTERN_KEY_REGEX);

  if (matchedContent) {
    const fileURL = matchedContent[1].trim();
    fileURLs = fileURL.slice(1, -1).split(',');
    content = content.replace(FILE_PATTERN_KEY_REGEX, '').trim();
  }

  // Now with the text content lets convert all urls to hyperlinks.
  const convertContentUrlsToLinks = (textContent: string) => {
    const convertedContent = textContent.replace(
      /\b(https?|ftp):\/\/(www\.)?\S+|\bwww\.\S+/g,
      match => {
        return `<a href="${
          match.startsWith('www.') ? `http://${match}` : match
        }" target="_blank">${match}</a>`;
      },
    );

    return DOMPurify.sanitize(convertedContent);
  };

  content = convertContentUrlsToLinks(content);

  return (
    <>
      <p
        className={`px-5 py-4 whitespace-pre-line break-words text-xs rounded-[20px] ${
          message.isSender ? 'bg-river-blue text-white' : 'bg-[#e4e4e4] text-river-black'
        } ${fileURLs.length === 1 ? 'max-w-[240px]' : fileURLs.length > 1 ? 'max-w-[450px]' : ''}`}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />

        {fileURLs.length > 0 ? (
          <div className="flex flex-row flex-wrap justify-center [&>a]:ml-0 [&>a]:mr-0 [&>a]:!h-[200px] gap-1 mt-3">
            {fileURLs
              .filter(fileURL => isValidURL(fileURL))
              .map(fileURL => (
                <FileBubble
                  message={{
                    ...message,
                    type: (getFileType(fileURL.split('?')[0]) === 'image'
                      ? 'IMAGE'
                      : 'FILE') as MessageContentType,
                    content: fileURL,
                    isSender: message.isSender,
                  }}
                  properties={{ minWidth: 200, maxWidth: 250 }}
                />
              ))}
          </div>
        ) : null}
      </p>
    </>
  );
}

function QuestionnaireBubble({ message }: { message: Message }) {
  let getCardType = CARD_BUBBLE_TYPE.find(card => card.tag === message.content) || {
    tag: 'No Content Found',
    title: 'No Content Found',
    icon: 'iconChatCardVisit',
    btnText: 'VIEW FILE',
    iconSize: '26px',
    color: '#CBCBCB',
    bgColor: '#E9E9E9',
    url: '',
  };

  if (message.type === 'LAB-REPORT') {
    getCardType = {
      tag: 'LAB-REPORT',
      title: 'Lab Report',
      icon: 'iconChatCardPrescription',
      btnText: 'VIEW LAB RESULT',
      iconSize: '22px',
      color: '#FF8C42',
      bgColor: '#FFE6D6',
      url: paths.VIEW_LAB_RESULT_PATH.replace(':labTestId', message?.patientLabTestId || ''),
    };
  }

  if (message.type === 'QUESTIONNAIRE') {
    getCardType = {
      ...getCardType,
      url: getCardType.url.replace(':visitId', message?.patientQuestionnaireId || ''),
    };
  }

  return (
    <div
      style={{ background: getCardType.bgColor }}
      className="flex flex-col items-center justify-center rounded-[16px] text-white"
    >
      <div
        style={{ background: getCardType.color }}
        className="relative flex flex-row items-center justify-start px-6 py-4 rounded-[16px] space-x-5 min-w-[270px]"
      >
        <div className="w-[40px] h-[40px] rounded-full flex items-center justify-center bg-white">
          <Icon
            id={getCardType.icon}
            style={{ color: getCardType.color, width: getCardType.iconSize }}
          />
        </div>
        <div>
          <h2 className="text-xs font-poppins-medium">{getCardType.title}</h2>
          <span className="text-[11px]">{moment(message.createdAt).format('MM/DD/YY')}</span>
        </div>

        <Graphic
          id="graphicChatCardTexture"
          className="absolute right-[-20px] bottom-0 w-20 !mr-0 !ml-0 inline-flex"
        />
      </div>

      <a href={getCardType.url}>
        <span
          style={{ color: getCardType.color }}
          className="py-2 text-xs font-poppins-medium inline-block"
        >
          {getCardType.btnText}
        </span>
      </a>
    </div>
  );
}

function VideoCallBubble({ message }: { message: Message }) {
  const whereByLink = message.content;
  const callPath = `${window.location.origin}${paths.VIDEO_CALL_INTRO_URL_PATH}?link=${whereByLink}`;

  return (
    <div
      style={{ background: '#FFE7E3' }}
      className="flex flex-col items-center justify-center rounded-[16px] text-white bg-[#FFE7E3]"
    >
      <div
        style={{ background: '#FF6045' }}
        className="relative flex flex-row items-center justify-start px-6 py-4 rounded-[16px] space-x-5 min-w-[270px] "
      >
        <div className="w-[40px] h-[40px] rounded-full flex items-center justify-center bg-white">
          <Icon id="iconChatCardVideoCall" style={{ color: '#FF6045', width: '50px' }} />
        </div>
        <div>
          <h2 className="text-xs font-poppins-medium">Virtual Visit Call</h2>
          <span className="text-[11px]">Video consult with doctor</span>
        </div>

        <Graphic
          id="graphicChatCardTexture"
          className="absolute right-[-20px] bottom-0 w-20 !mr-0 !ml-0 inline-flex"
        />
      </div>

      <a href={callPath}>
        <span
          style={{ color: '#FF6045' }}
          className="py-2 text-xs font-poppins-medium inline-block "
        >
          CLICK TO JOIN CALL
        </span>
      </a>
    </div>
  );
}

function FileBubble({
  message,
  properties,
}: {
  message: Message;
  properties?: { minWidth: number; maxWidth: number };
}) {
  const filename = getFileNameFromURL(message.content.split('?')[0]);

  return message.type === 'IMAGE' ? (
    <a
      href={message.content}
      target="_blank"
      rel="noreferrer"
      style={{
        height: message?.imageHeight
          ? message?.imageHeight > 300
            ? 300
            : message?.imageHeight
          : 300,
        ...(properties?.minWidth && {
          minWidth: properties?.minWidth,
        }),
        ...(properties?.maxWidth && {
          maxWidth: properties?.maxWidth,
        }),
      }}
      className={`${
        message.isSender
          ? 'bg-[#F8F8F8] text-white ml-auto'
          : 'bg-[#F8F8F8] text-river-black mr-auto'
      } relative block p-[3px] rounded-[16px] min-w-[300px] max-w-[400px] w-[40%] hover:opacity-80`}
    >
      {message?.tempMessageId && (
        <Icon id="loader" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
      )}

      <Image
        className="w-full h-full rounded-[16px]"
        src={message.content}
        alt="chat image"
        bgColor={message?.imageBgPlaceholder || ''}
      />
    </a>
  ) : (
    <a
      href={message.content}
      target="_blank"
      rel="noreferrer"
      style={{
        height: message?.imageHeight
          ? message?.imageHeight > 300
            ? 300
            : message?.imageHeight
          : 300,
        ...(properties?.minWidth && {
          minWidth: properties?.minWidth,
        }),
        ...(properties?.maxWidth && {
          maxWidth: properties?.maxWidth,
        }),
      }}
      className={`${
        message.isSender ? 'ml-auto' : 'mr-auto'
      } bg-gray-200 relative rounded-[16px] overflow-hidden border-[1px] border-[#d1d2d31a] min-w-[300px] 
      max-w-[400px] w-[40%] hover:opacity-80 flex flex-col`}
    >
      <div className="h-16 flex flex-row space-x-2 cursor-pointer m-[3px]">
        <button type="button" className="h-16 w-16 shrink-0">
          <span
            className={`flex items-center h-full w-full justify-center bg-cover bg-center rounded-[16px] ${
              filename.toLocaleLowerCase().endsWith('.pdf') ? 'bg-[#E01E5A]' : 'bg-[#1264a4]'
            }`}
          >
            <IconSprite
              id={
                filename.toLocaleLowerCase().endsWith('.pdf')
                  ? 'icon-pdf'
                  : filename.toLocaleLowerCase().endsWith('.doc') ||
                    filename.toLocaleLowerCase().endsWith('.docx')
                  ? 'icon-docx'
                  : 'icon-paper-file'
              }
              className={`flex items-center text-white ${
                filename.toLocaleLowerCase().endsWith('.pdf')
                  ? 'h-[35px] w-[35px]'
                  : filename.toLocaleLowerCase().endsWith('.doc') ||
                    filename.toLocaleLowerCase().endsWith('.docx')
                  ? 'h-[40px] w-[40px]'
                  : 'h-[30px] w-[30px]'
              }`}
            />
          </span>
        </button>
        <span className="w-full text-river-black flex-1 justify-center flex flex-col align-middle leading-[22px] overflow-hidden">
          <span className="block text-[15px] font-medium whitespace-pre overflow-hidden text-ellipsis">
            {filename || 'Attached File'}
          </span>
          <span className="text-[13px] block font-light">
            {filename.toLocaleLowerCase().endsWith('.pdf')
              ? 'PDF'
              : filename.toLocaleLowerCase().endsWith('.doc') ||
                filename.toLocaleLowerCase().endsWith('.docx')
              ? 'Word Document'
              : `${getFileExtension(filename).toLocaleUpperCase()} File`}
          </span>
        </span>
      </div>
      <div className="flex-1 bg-white relative overflow-hidden">
        {message?.tempMessageId && (
          <Icon
            id="loader"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-200"
          />
        )}

        <Icon
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] opacity-30"
          id="iconChatDocumentPreviewDownload"
        />
      </div>
    </a>
  );
}

FileBubble.defaultProps = {
  properties: undefined,
};

const CARD_BUBBLE_TYPE: Array<{
  tag: string;
  title: string;
  icon: string;
  btnText: string;
  iconSize: string;
  color: string;
  bgColor: string;
  url: string;
}> = [
  {
    tag: 'Birth Control Questionnaire answered',
    title: 'Birth Control Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['birth-control'] && visitIconsAndColors['birth-control']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=birth-control&id=:visitId`,
  },
  {
    tag: 'Birth Control Prescription available',
    title: 'Birth Control Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#2FAEF3',
    bgColor: 'rgb(229, 246, 255)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=birth-control&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Sick Questionnaire answered',
    title: 'Sick Visit',
    btnText: 'VIEW VISIT',
    iconSize: '20px',
    ...(visitIconsAndColors.sick && visitIconsAndColors.sick),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=sick&id=:visitId`,
  },
  {
    tag: 'Sick Prescription available',
    title: 'Sick Visit Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FF8C42',
    bgColor: '#FFE6D6',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=sick&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'UTI Questionnaire answered',
    title: 'UTI Treatment Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors.uti && visitIconsAndColors.uti),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=uti&id=:visitId`,
  },
  {
    tag: 'UTI Prescription available',
    title: 'UTI Treatment Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#F4ACB8',
    bgColor: '#FFE1E6',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=uti&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Mental Health Questionnaire answered',
    title: 'Behavioral Health Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['mental-health'] && visitIconsAndColors['mental-health']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=mental-health&id=:visitId`,
  },
  {
    tag: 'Mental Health Prescription available',
    title: 'Behavioral Health Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#d8ae39',
    bgColor: 'rgb(255, 245, 209)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=mental-health&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'STD Diagnosis Questionnaire answered',
    title: 'STD Diagnosis Visit',
    btnText: 'VIEW VISIT',
    iconSize: '23px',
    ...(visitIconsAndColors['std-diagnosis'] && visitIconsAndColors['std-diagnosis']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=std-diagnosis&id=:visitId`,
  },
  {
    tag: 'STD Diagnosis Prescription available',
    title: 'STD Diagnosis Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FFC26F',
    bgColor: '#FFE0AF',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=std-diagnosis&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'STD Test Questionnaire answered',
    title: 'STD Test Visit',
    btnText: 'VIEW VISIT',
    iconSize: '23px',
    ...(visitIconsAndColors['std-test'] && visitIconsAndColors['std-test']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=std-test&id=:visitId`,
  },
  {
    tag: 'STD Test Prescription available',
    title: 'STD Test Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FFC26F',
    bgColor: '#FFE0AF',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=std-test&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Genital Herpes Questionnaire answered',
    title: 'Genital Herpes Visit',
    btnText: 'VIEW VISIT',
    iconSize: '23px',
    ...(visitIconsAndColors['genital-herpes'] && visitIconsAndColors['genital-herpes']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=genital-herpes&id=:visitId`,
  },
  {
    tag: 'Genital Herpes Prescription available',
    title: 'Genital Herpes Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FFC26F',
    bgColor: '#FFE0AF',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=genital-herpes&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Cold Sores Questionnaire answered',
    title: 'Cold Sores Visit',
    btnText: 'VIEW VISIT',
    iconSize: '23px',
    ...(visitIconsAndColors['cold-sores'] && visitIconsAndColors['cold-sores']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=cold-sores&id=:visitId`,
  },
  {
    tag: 'Cold Sores Prescription available',
    title: 'Cold Sores Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FFC26F',
    bgColor: '#FFE0AF',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=cold-sores&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Vaginal Infections Questionnaire answered',
    title: 'Vaginal Infections Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['vaginal-infections'] && visitIconsAndColors['vaginal-infections']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=vaginal-infections&id=:visitId`,
  },
  {
    tag: 'Vaginal Infections Prescription available',
    title: 'Vaginal Infections Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#B760FF',
    bgColor: '#F4E7FF',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=vaginal-infections&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Menstrual Irregularities Questionnaire answered',
    title: 'Menstrual Irregularities Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['menstrual-irregularities'] &&
      visitIconsAndColors['menstrual-irregularities']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=menstrual-irregularities&id=:visitId`,
  },
  {
    tag: 'Menstrual Irregularities Prescription available',
    title: 'Menstrual Irregularities Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#FFAE2D',
    bgColor: 'rgb(255, 246, 231)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=menstrual-irregularities&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Erectile Dysfunction Questionnaire answered',
    title: 'Erectile Dysfunction Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['erectile-dysfunction'] && visitIconsAndColors['erectile-dysfunction']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=erectile-dysfunction&id=:visitId`,
  },
  {
    tag: 'Erectile Dysfunction Prescription available',
    title: 'Erectile Dysfunction Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#EAA37D',
    bgColor: 'rgb(255, 238, 227)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=erectile-dysfunction&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'Hair Skin Questionnaire answered',
    title: 'Hair & Skin Visit',
    btnText: 'VIEW VISIT',
    iconSize: '32px',
    ...(visitIconsAndColors['hair-skin'] && visitIconsAndColors['hair-skin']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=hair-skin&id=:visitId`,
  },
  {
    tag: 'Hair Skin Prescription available',
    title: 'Hair & Skin Summary',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#68C5A3',
    bgColor: 'rgb(222, 255, 243)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=hair-skin&id=:visitId&tab=PRESCRIPTION`,
  },
  {
    tag: 'RX Refills Questionnaire answered',
    title: 'Rx Request Visit',
    btnText: 'VIEW VISIT',
    iconSize: '26px',
    ...(visitIconsAndColors['rx-refills'] && visitIconsAndColors['rx-refills']),
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=rx-refills&id=:visitId`,
  },
  {
    tag: 'RX Refills Prescription available',
    title: 'Prescription Refill',
    icon: 'iconChatCardPrescription',
    btnText: 'VIEW VISIT SUMMARY',
    iconSize: '22px',
    color: '#2FAEF3',
    bgColor: 'rgb(229, 246, 255)',
    url: `${paths.CARE_HISTORIES_VISIT_URL_PATH}?type=rx-refills&id=:visitId&tab=PRESCRIPTION`,
  },
];
