import React from 'react';
import ReactPinInput from 'react-pin-input';

type PinInputProps = {
  type: 'numeric' | 'custom';
  initialValue: string | number;
  length: number;
  error: boolean;
  focus?: boolean;
  secret?: boolean;
  onChange: (value: string) => void;
  onComplete?: (value: string, index: number) => void;
};

function PinInput({
  type,
  initialValue,
  length,
  focus,
  secret,
  onChange,
  onComplete,
  error,
}: PinInputProps) {
  return (
    <div className={`pin-input ${error ? 'error' : ''}`}>
      <ReactPinInput
        type={type}
        initialValue={initialValue}
        length={length || 4}
        focus={focus}
        secret={secret}
        onChange={onChange}
        onComplete={onComplete}
        inputMode={type}
        style={undefined}
        inputStyle={undefined}
        inputFocusStyle={undefined}
      />
    </div>
  );
}

PinInput.defaultProps = {
  focus: true,
  secret: true,
  onComplete: (value: string, index: number) => false,
};

export default PinInput;
