import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PopupButton } from '@typeform/embed-react';
import { Icon, Image, OptionsMenu, OptionButton, OptionList, OptionItem } from '..';
import { ReactComponent as RiverLogo } from '../../assets/images/image_river_logo.svg';
import { AuthUser, getCompany } from '../../context/authContext';
import { paths } from '../../routes';
import env from '../../utils/env';
import ActivateCardPrompt from '../Modal/ActivateCardPrompt/ActivateCardPrompt';

type NavigationProps = {
  user: AuthUser | null;
  onSignOut: () => void;
};

function Navigation({ user, onSignOut }: NavigationProps) {
  const [showActivateCardModal, setShowActivateCardModal] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const familyPlanTypeFormId = user
    ? getCompany(user)?.familyPlanTypeformId || env('RIVER_FAMILY_PLAN_TYPEFORM_ID')
    : env('RIVER_FAMILY_PLAN_TYPEFORM_ID');

  const NOT_ACCESSIBLE = Boolean(
    user &&
      getCompany(user)?.name === 'Cracker Barrel & Maple Street Biscuit Company OE 2024' &&
      !user?.insurance?.subscriberId,
  );

  return (
    <>
      <header
        className="bg-white text-river-black shadow-sm  border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 fixed top-0
      inset-x-0 z-50 flex-none min-h-[80px] tablet:min-h-[80px]"
      >
        <nav className="px-5 tablet:px-10 desktop:px-16">
          <div className="flex items-center justify-between h-20 tablet:h-24">
            <div className="flex items-center">
              <Link to={paths.HOME_URL_PATH} className="shrink-0">
                <RiverLogo className="w-16" />
              </Link>
            </div>
            <div className="">
              <div className="ml-4 flex items-center space-x-8 tablet:space-x-10">
                <div className="ml-3 relative">
                  <OptionsMenu>
                    <OptionButton className="max-w-xs flex items-center text-[15px] font-poppins-medium">
                      <Image
                        className="h-[50px] w-[50px] tablet:h-12 tablet:w-12 rounded-full tablet:mr-3"
                        src={user?.signedAvatar || 'image_river_avatar_placeholder.svg'}
                        alt="user avatar"
                        bgColor={user?.avatarPlaceholderColor || ''}
                      />
                      <span className="hidden tablet:inline-flex">
                        {`${user?.firstName} ${user?.lastName}`}
                      </span>
                      <Icon id="arrowDown" className="h-5 w-5 hidden tablet:inline-flex" />
                    </OptionButton>
                    {!NOT_ACCESSIBLE ? (
                      <OptionList
                        className="!min-w-[200px] mt-3 right-0 rounded-xl shadow-[0_0px_15px_0px_rgba(0,0,0,0.08)]
                    bg-white border-[0.5px] border-[#e9ecef] ring-[#e9ecef] ring-opacity-5 focus:outline-none"
                      >
                        <OptionItem
                          className="text-[15px] text-gray-700"
                          onClick={() => navigate(paths.PROFILE_URL_PATH)}
                        >
                          <Icon
                            id="iconUserProfileMenu"
                            className="w-[15px] inline-flex mr-3 text-[#676767] group-hover:fill-river-blue"
                          />
                          Profile
                        </OptionItem>

                        {familyPlanTypeFormId && (
                          <OptionItem className="text-[15px] text-gray-700" onClick={() => false}>
                            <PopupButton id={familyPlanTypeFormId}>
                              <Icon
                                id="iconAddFamily"
                                className="w-[15px] inline-flex mr-3 text-[#676767] stroke-[#676767] group-hover:fill-river-blue group-hover:stroke-river-blue"
                              />
                              Add Family
                            </PopupButton>
                          </OptionItem>
                        )}

                        <OptionItem
                          className="text-[15px] text-gray-700"
                          onClick={() => setShowActivateCardModal(true)}
                        >
                          <Icon
                            id="iconCardActivate"
                            className="w-[18px] inline-flex mr-3 text-[#676767] stroke-[#676767] group-hover:fill-river-blue 
                          group-hover:stroke-river-blue group-hover:text-river-blue"
                          />
                          Activate Card
                        </OptionItem>

                        <OptionItem className="text-[15px] text-gray-700" onClick={() => false}>
                          <PopupButton id="Zd7M4QYX">
                            <Icon
                              id="iconReimbursement"
                              className="w-[23px] inline-flex mr-3 text-[#676767] stroke-[#676767] group-hover:fill-river-blue 
                            group-hover:stroke-river-blue group-hover:text-river-blue"
                            />
                            Reimbursement
                          </PopupButton>
                        </OptionItem>

                        <OptionItem
                          className="text-[15px] text-gray-700"
                          onClick={() =>
                            window.open('https://www.river.health/terms-of-service', '_blank')
                          }
                        >
                          <Icon
                            id="iconUserTermsOfService"
                            className="w-[20px] inline-flex mr-3 text-[#676767] stroke-[#676767] group-hover:fill-river-blue 
                          group-hover:stroke-river-blue group-hover:text-river-blue"
                          />
                          Terms of Service
                        </OptionItem>

                        <OptionItem
                          className="text-[15px] text-gray-700"
                          onClick={() =>
                            window.open('https://www.river.health/privacy-policy', '_blank')
                          }
                        >
                          <Icon
                            id="iconUserPrivacyPolicy"
                            className="w-[20px] inline-flex mr-3 text-[#676767] stroke-[#676767] group-hover:fill-river-blue 
                          group-hover:stroke-river-blue group-hover:text-river-blue"
                          />
                          Privacy Policy
                        </OptionItem>

                        <OptionItem className="text-[15px] text-gray-700" onClick={onSignOut}>
                          <Icon
                            id="iconUserProfileMenuLogout"
                            className="w-5 inline-flex mr-3 text-[#676767] group-hover:fill-river-blue"
                          />
                          Logout
                        </OptionItem>
                      </OptionList>
                    ) : null}
                  </OptionsMenu>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {user && (showActivateCardModal || location.pathname === paths.ACTIVATE_CARD_URL_PATH) && (
        <ActivateCardPrompt
          onCloseModal={() => {
            if (params.get('w') && params.get('w') === 'close') {
              window.close();
              return;
            }

            if (location.pathname === paths.ACTIVATE_CARD_URL_PATH) {
              navigate(paths.HOME_URL_PATH);
              return;
            }
            setShowActivateCardModal(false);
          }}
          user={user}
        />
      )}
    </>
  );
}

export default Navigation;
