import moment from 'moment';

const formatChatTime = (time: string) => {
  const currentTime = moment(Date.now()).format('MM/DD');
  const chatTime = moment(time).format('MM/DD');

  if (currentTime === chatTime) {
    return moment(time).format('h:mm a');
  }

  return moment(time).format('MM/DD/YYYY');
};

export default formatChatTime;
