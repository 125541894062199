import * as Cookies from 'js-cookie';
import * as CryptoJS from 'crypto-js';
import env from './env';

const passPhrase = env('RIVER_PASSPHRASE') || '';

/**
 * Cookie keys are; _river_tokid(token id) | _river_usrid(user id) |
 * _river_unread_msg (unread msgs) | _river_error_ch_msgs (error chat messages)
 * @param key
 * @param value
 * @param expires
 */
const setCookie = (key: string, value: string, expires = 7) => {
  const cipheredValue = CryptoJS.AES.encrypt(value, passPhrase).toString();

  if (env('RIVER_ENV') === 'development') {
    Cookies.set(key, cipheredValue);
    return;
  }

  if (env('RIVER_ENV') === 'staging') {
    Cookies.set(key, cipheredValue);
    return;
  }

  const options: Cookies.CookieAttributes = {
    expires,
    sameSite: 'Lax',
    secure: true,
  };

  Cookies.set(key, cipheredValue, options);
};

export default setCookie;
