// For detail info, we may go with google geocoding api
// https://developers.google.com/maps/documentation/geocoding/overview?csw=1
// https://developers.google.com/maps/documentation/javascript/geocoding#Geocoding

export const getStateFromZipCode = (code: string | number) => {
  const zipCode = parseInt(code?.toString(), 10);

  let state: { code?: string | string[]; name?: string | string[] } = {
    code: undefined,
    name: undefined,
  };

  // We have enable billing for this to work else REQUEST_DENIED
  // const geoCoder = new window.google.maps.Geocoder()
  //
  // geoCoder.geocode({ address: code }, function (results: any, status: any) {
  //   console.log('GeoCoder results', results)
  //   console.log('GeoCoder status', status)
  // })

  if (zipCode >= 35000 && zipCode <= 36999) {
    state = {
      code: 'AL',
      name: 'Alabama',
    };
  } else if (zipCode >= 99500 && zipCode <= 99999) {
    state = {
      code: 'AK',
      name: 'Alaska',
    };
  } else if (zipCode >= 85000 && zipCode <= 86999) {
    state = {
      code: 'AZ',
      name: 'Arizona',
    };
  } else if (zipCode >= 71600 && zipCode <= 72999) {
    state = {
      code: 'AR',
      name: 'Arkansas',
    };
  } else if (zipCode >= 90000 && zipCode <= 96699) {
    state = {
      code: 'CA',
      name: 'California',
    };
  } else if (zipCode >= 80000 && zipCode <= 81999) {
    state = {
      code: 'CO',
      name: 'Colorado',
    };
  } else if (zipCode >= 6000 && zipCode <= 6999) {
    state = {
      code: 'CT',
      name: 'Connecticut',
    };
  } else if (zipCode >= 19700 && zipCode <= 19999) {
    state = {
      code: 'DE',
      name: 'Delaware',
    };
  } else if (zipCode >= 32000 && zipCode <= 34999) {
    state = {
      code: 'FL',
      name: 'Florida',
    };
  } else if (zipCode >= 30000 && zipCode <= 31999) {
    state = {
      code: 'GA',
      name: 'Georgia',
    };
  } else if (zipCode >= 96700 && zipCode <= 96999) {
    state = {
      code: 'HI',
      name: 'Hawaii',
    };
  } else if (zipCode >= 83200 && zipCode <= 83999) {
    state = {
      code: 'ID',
      name: 'Idaho',
    };
  } else if (zipCode >= 60000 && zipCode <= 62999) {
    state = {
      code: 'IL',
      name: 'Illinois',
    };
  } else if (zipCode >= 46000 && zipCode <= 47999) {
    state = {
      code: 'IN',
      name: 'Indiana',
    };
  } else if (zipCode >= 50000 && zipCode <= 52999) {
    state = {
      code: 'IA',
      name: 'Iowa',
    };
  } else if (zipCode >= 66000 && zipCode <= 67999) {
    state = {
      code: 'KS',
      name: 'Kansas',
    };
  } else if (zipCode >= 40000 && zipCode <= 42999) {
    state = {
      code: 'KY',
      name: 'Kentucky',
    };
  } else if (zipCode >= 70000 && zipCode <= 71599) {
    state = {
      code: 'LA',
      name: 'Louisiana',
    };
  } else if (zipCode >= 3900 && zipCode <= 4999) {
    state = {
      code: 'ME',
      name: 'Maine',
    };
  } else if (zipCode >= 20600 && zipCode <= 21999) {
    state = {
      code: 'MD',
      name: 'Maryland',
    };
  } else if (zipCode >= 1000 && zipCode <= 2799) {
    state = {
      code: 'MA',
      name: 'Massachusetts',
    };
  } else if (zipCode >= 48000 && zipCode <= 49999) {
    state = {
      code: 'MI',
      name: 'Michigan',
    };
  } else if (zipCode >= 55000 && zipCode <= 56999) {
    state = {
      code: 'MN',
      name: 'Minnesota',
    };
  } else if (zipCode >= 38600 && zipCode <= 39999) {
    state = {
      code: 'MS',
      name: 'Mississippi',
    };
  } else if (zipCode >= 63000 && zipCode <= 65999) {
    state = {
      code: 'MO',
      name: 'Missouri',
    };
  } else if (zipCode >= 59000 && zipCode <= 59999) {
    state = {
      code: 'MT',
      name: 'Montana',
    };
  } else if (zipCode >= 27000 && zipCode <= 28999) {
    state = {
      code: 'NC',
      name: 'North Carolina',
    };
  } else if (zipCode >= 58000 && zipCode <= 58999) {
    state = {
      code: 'ND',
      name: 'North Dakota',
    };
  } else if (zipCode >= 68000 && zipCode <= 69999) {
    state = {
      code: 'NE',
      name: 'Nebraska',
    };
  } else if (zipCode >= 88900 && zipCode <= 89999) {
    state = {
      code: 'NV',
      name: 'Nevada',
    };
  } else if (zipCode >= 3000 && zipCode <= 3899) {
    state = {
      code: 'NH',
      name: 'New Hampshire',
    };
  } else if (zipCode >= 7000 && zipCode <= 8999) {
    state = {
      code: 'NJ',
      name: 'New Jersey',
    };
  } else if (zipCode >= 87000 && zipCode <= 88499) {
    state = {
      code: 'NM',
      name: 'New Mexico',
    };
  } else if (zipCode >= 10000 && zipCode <= 14999) {
    state = {
      code: 'NY',
      name: 'New York',
    };
  } else if (zipCode >= 43000 && zipCode <= 45999) {
    state = {
      code: 'OH',
      name: 'Ohio',
    };
  } else if (zipCode >= 73000 && zipCode <= 74999) {
    state = {
      code: 'OK',
      name: 'Oklahoma',
    };
  } else if (zipCode >= 97000 && zipCode <= 97999) {
    state = {
      code: 'OR',
      name: 'Oregon',
    };
  } else if (zipCode >= 15000 && zipCode <= 19699) {
    state = {
      code: 'PA',
      name: 'Pennsylvania',
    };
  } else if (zipCode >= 300 && zipCode <= 999) {
    state = {
      code: 'PR',
      name: 'Puerto Rico',
    };
  } else if (zipCode >= 2800 && zipCode <= 2999) {
    state = {
      code: 'RI',
      name: 'Rhode Island',
    };
  } else if (zipCode >= 29000 && zipCode <= 29999) {
    state = {
      code: 'SC',
      name: 'South Carolina',
    };
  } else if (zipCode >= 57000 && zipCode <= 57999) {
    state = {
      code: 'SD',
      name: 'South Dakota',
    };
  } else if (zipCode >= 37000 && zipCode <= 38599) {
    state = {
      code: 'TN',
      name: 'Tennessee',
    };
  } else if ((zipCode >= 75000 && zipCode <= 79999) || (zipCode >= 88500 && zipCode <= 88599)) {
    state = {
      code: 'TX',
      name: 'Texas',
    };
  } else if (zipCode >= 84000 && zipCode <= 84999) {
    state = {
      code: 'UT',
      name: 'Utah',
    };
  } else if (zipCode >= 5000 && zipCode <= 5999) {
    state = {
      code: 'VT',
      name: 'Vermont',
    };
  } else if (zipCode >= 20000 && zipCode <= 24699) {
    // Initially this was starting from (22000 to 24699) but now Virginia is mixing states up with Washington DC (20000 to 20599)
    state = {
      code: ['VA', 'DC'],
      name: ['Virginia', 'Washington DC'],
    };
  } else if (zipCode >= 98000 && zipCode <= 99499) {
    state = {
      code: 'WA',
      name: 'Washington',
    };
  } else if (zipCode >= 24700 && zipCode <= 26999) {
    state = {
      code: 'WV',
      name: 'West Virginia',
    };
  } else if (zipCode >= 53000 && zipCode <= 54999) {
    state = {
      code: 'WI',
      name: 'Wisconsin',
    };
  } else if (zipCode >= 82000 && zipCode <= 83199) {
    state = {
      code: 'WY',
      name: 'Wyoming',
    };
  } else {
    state = {
      code: undefined,
      name: undefined,
    };
  }

  return state;
};

export const hasStateMatchingZipCode = (state?: string, code?: string | number) => {
  if (!(state && code)) return false;
  return Boolean(getStateFromZipCode(code).code?.includes(state));
};

export default getStateFromZipCode;
