/* eslint-disable react/no-danger */
import React from 'react';
import markdown from '../../utils/markdown';

type MarkdownHTMLProps = {
  className?: string;
  content: string;
};

function MarkdownHTML({ className, content }: MarkdownHTMLProps) {
  return (
    <p
      className={`markdown ${className}`}
      dangerouslySetInnerHTML={{
        __html: markdown(content),
      }}
    />
  );
}

MarkdownHTML.defaultProps = {
  className: '',
};

export default MarkdownHTML;
