import React from 'react';
import { Button, Icon, Modal } from '../..';

type ModalProps = {
  open: boolean;
  onCloseModal: () => void;
};

function ReferralSentPrompt({ onCloseModal, open }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show={open}>
      <div className="rounded-xl bg-white tablet:w-[450px]">
        <div className="flex flex-col p-5 pt-8 pb-10 items-center text-center">
          <span className="w-24 h-24 bg-[#FFF8ED] rounded-full flex">
            <Icon id="iconMail" className="w-14 self-center mx-auto" />
          </span>
          <p className="text-river-black text-sm mb-2 font-normal mt-6">
            Got it! Your provider has received your referral request. Expect an email or text
            message from our support team shortly.
          </p>
          <div className="mt-12 tablet:mt-5">
            <Button label="Okay" onClick={onCloseModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ReferralSentPrompt;
