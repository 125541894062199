/* eslint-disable @typescript-eslint/no-shadow */
import * as React from 'react';
import { Graphic } from '../..';
import capitalize from '../../../utils/capitalize';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CustomInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  inputs: Array<{
    id: string;
    priority: number;
    text: string;
  }>;
  onInputChange: (answers: Answer) => void;
};

function CustomCardSelectorInputBuilder({
  id,
  label,
  value,
  inputs,
  onInputChange,
}: CustomInputBuilderProps) {
  const [cardValue, setCardValue] = React.useState(value);

  const handleCardSelected = (value: string) => {
    onInputChange({
      questionKey: id,
      answers: [value],
    });
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
      </div>

      <div
        className={`grid grid-cols-1 gap-5 ${
          inputs.length > 2 ? 'tablet:grid-cols-3' : 'tablet:grid-cols-2'
        }`}
      >
        {inputs &&
          inputs.map(input => (
            <div
              aria-hidden
              key={input.id}
              onClick={() => handleCardSelected(input.id)}
              className="bg-[#FFE0AF] h-[130px] tablet:h-[140px] tablet:min-h-fit laptop:min-h-[140px] relative 
              rounded-xl cursor-pointer"
            >
              <h3 className="font-medium text-[15px] absolute bottom-2 left-4 z-10">
                {input.text}
              </h3>
              <Graphic
                id={`graphic${capitalize(input.text).replace(/[^a-zA-Z0-9]+/g, '')}`}
                className="absolute object-center object-cover h-[100px]
                right-0 bottom-0 tablet:right-0 tablet:h-[100px]"
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default CustomCardSelectorInputBuilder;
