import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';

const customValidators = {
  phone: {
    message: 'The :attribute must be a valid US number.',
    rule: (val: string) => {
      return isValidPhoneNumber(val);
    },
    required: false,
  },
  date: {
    message: 'The :attribute must be a date (mm/dd/yyyy).',
    rule: (val: string) => {
      return moment(val, 'MM/DD/YYYY', true).isValid();
    },
    required: false,
  },
  dob: {
    message: 'The date of birth must be valid.',
    messageReplace: (message: string, params?: Array<string>) => {
      if (!params || params.length === 0) {
        return message;
      }
      const age = moment().diff(params[0], 'years');
      return age > 120
        ? `Wow, that's too old to be using this app`
        : 'Your age must be at least 14 years old.';
    },
    rule: (val: string) => {
      const age = moment().diff(val, 'years');
      return age >= 14 && age <= 120;
    },
    required: false,
  },
  height: {
    message: `The height must be in feet and inches (e.g., 5'9").`,
    rule: (val: string, params: any, validator: any) => {
      // const heightRegex = /^(0*[1-9]\d*)?'(0*\d|1[0-1])"$/; ['’]|
      // We are keeping height as (3' to 7'11") [3-7] because it is a sensible range of height for human
      const heightRegex = /^[3-7]['’](?:\s*(?:1[01]|[0-9])(["”]))?$/;
      return validator.helpers.testRegex(val, heightRegex);
    },
  },
};

export default customValidators;
