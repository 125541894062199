/* eslint-disable @typescript-eslint/no-shadow */
import moment from 'moment';
import * as React from 'react';
import { Input } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type DateInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function DateInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: DateInputBuilderProps) {
  const [dateValues, setDateValues] = React.useState(() => {
    if (value) {
      const month = moment(value, 'YYYY-MM-DD').format('MM');
      const day = moment(value, 'YYYY-MM-DD').format('DD');
      const year = moment(value, 'YYYY-MM-DD').format('YYYY');

      return { month, day, year };
    }

    return { month: '', day: '', year: '' };
  });

  const handleDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (!value.match('^[0-9/]*$')) {
      return;
    }

    setDateValues(date => ({ ...date, [name]: value }));

    const dateValue = Object.values({ ...dateValues, [name]: value }).join('/');

    if (moment(dateValue, 'MM/DD/YYYY', true).isValid()) {
      onInputChange({
        questionKey: id,
        answers: [moment(dateValue, 'MM/DD/YYYY').format('YYYY-MM-DD')],
      });
    } else {
      onInputChange({ questionKey: id, answers: [''] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-2">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>
      <div className="grid grid-cols-3 gap-4 tablet:gap-10 laptop:gap-16">
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0 text-center"
          name="month"
          placeholder="mm"
          value={dateValues.month}
          onChange={handleDateInputChange}
          maxLength={2}
          error={Boolean(error)}
        />

        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0 text-center"
          name="day"
          placeholder="dd"
          value={dateValues.day}
          onChange={handleDateInputChange}
          maxLength={2}
          error={Boolean(error)}
        />

        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
        focus:border-b-[1.5px] focus:ring-0 text-center"
          name="year"
          placeholder="yyyy"
          value={dateValues.year}
          onChange={handleDateInputChange}
          maxLength={4}
          error={Boolean(error)}
        />
      </div>
      {error && <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

DateInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default DateInputBuilder;
