import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorGraphicIllustration from '../../assets/images/image_error_graphic_illustration.png';
import { Button, MarkdownHTML } from '..';
import env from '../../utils/env';
import { reportErrorToSentry } from '../../utils/reportToSentry';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
};

export function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  React.useEffect(() => {
    reportErrorToSentry(error);
  }, [error]);

  return (
    <div role="alert" className="flex flex-col items-center justify-center">
      <h3 className="text-[#FF9B5B] text-lg font-medium mb-2">Sorry !!!</h3>
      <span>
        <pre className="inline-flex">
          {error?.message || 'Something unusual happened. Kindly retry your last action.'}
        </pre>
      </span>

      <img
        src={ErrorGraphicIllustration}
        alt="error graphic"
        className="w-[250px] tablet:w-[350px]"
      />

      <Button label="Try again!" onClick={resetErrorBoundary} className="!px-2 mt-10" />

      <MarkdownHTML
        className="text-river-black text-xs mt-6 text-center"
        content={`If this persists, please reach out to our [support team.](${env(
          'RIVER_SUPPORT_URL',
        )})`}
      />
    </div>
  );
}

function ErrorBoundary({ children }: { children: JSX.Element }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={() => false}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
