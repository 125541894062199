/* eslint-disable no-nested-ternary */
import { isEmpty } from 'ramda';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../../Icon/Icon';
import uploadAWSS3File from '../../../utils/uploadAWSS3File';
import resizeImage from '../../../utils/resizeImage';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type InputBuilderProps = {
  id: string;
  label: string;
  value: string[];
  type: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function CustomFilesInputBuilder({
  id,
  label,
  value,
  type,
  error,
  notice,
  onInputChange,
}: InputBuilderProps) {
  const [file, setFile] = React.useState(
    value && value.length > 0 && value[0] !== 'NONE' ? value[0] : '',
  );

  const isMultipleFiles = type === 'OPEN-FILES';

  const [uploadingFile, setUploadingFile] = React.useState(false);

  const handleFileProcessing = async (inputFile: File) => {
    const filename = inputFile?.name;

    const fileType = inputFile?.type || 'image/jpeg';

    const fileKey = `document/${id}_${uuidv4()}.${filename ? filename.split('.').pop() : 'jpeg'}`;

    return uploadAWSS3File(inputFile, fileKey, fileType, 'public');
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    setUploadingFile(true);

    const getPromisedFiles = Array.from(files || []).map(async getFile => {
      if (getFile.type.startsWith('image/')) {
        return handleFileProcessing(await resizeImage(getFile, 0.7));
      }
      return handleFileProcessing(getFile);
    });

    const uploadedFiles = await Promise.all(getPromisedFiles);

    setUploadingFile(false);

    setFile(uploadedFiles[0]); // Let's get the first uploaded file

    onInputChange({ questionKey: id, answers: uploadedFiles });
  };

  return (
    <div>
      <div className="flex flex-col mb-6">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>

      <div
        className="fs-exclude relative flex flex-col items-center bg-[#EDF9FF] border-[1.5px] 
        rounded-xl border-dashed border-river-blue h-[200px] justify-center"
      >
        <div className="text-center">
          {uploadingFile ? (
            <>
              <Icon id="loader" className="w-10 mb-5 inline-flex" />
              <h3>Please wait...</h3>
            </>
          ) : isEmpty(file) ? (
            <>
              <Icon id="iconInputFileUpload" className="w-9 mb-5 inline-flex" />
              <h3>
                <span className="text-river-blue">Choose</span>{' '}
                {isMultipleFiles ? `file(s)` : 'file'} to upload
              </h3>
            </>
          ) : (
            <>
              <span className="mb-5 w-10 h-10 bg-river-blue inline-flex justify-center rounded-full items-center">
                <Icon id="checkmark" className="w-4 h-4 text-white" />
              </span>

              <h3>{isMultipleFiles ? `File(s)` : 'File'} uploaded!</h3>
            </>
          )}
        </div>

        <input
          className="absolute w-full h-full file:cursor-pointer right-0 left-0 top-0 cursor-pointer bg-slate-500 opacity-0"
          type="file"
          name="file"
          multiple={isMultipleFiles}
          accept="image/*"
          onChange={event => handleFileUpload(event)}
        />
      </div>

      {error && <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>}
    </div>
  );
}

CustomFilesInputBuilder.defaultProps = {
  error: undefined,
  notice: undefined,
};

export default CustomFilesInputBuilder;
