import * as React from 'react';
import { Button, Graphic, Icon, MarkdownHTML, Modal } from '../..';
import { paths } from '../../../routes';
import env from '../../../utils/env';

type ModalProps = {
  onCloseModal: () => void;
  prompt: string;
  visit: string;
};

function VisitNoticePrompt({ prompt, visit, onCloseModal }: ModalProps) {
  const prescriptionInst = [
    `Pick up formulary medication at your local pharmacy and pay out-of-pocket.`,
    `Submit a reimbursement at [app.helloriver.com](https://app.helloriver.com) > Profile > Reimbursement.`,
  ];

  return (
    <Modal onClose={onCloseModal} show>
      <div
        className={`rounded-xl bg-white  ${
          prompt === 'UNDER_MAINTENANCE' ? 'tablet:w-[550px] laptop:w-[700px]' : 'tablet:w-[550px] '
        }`}
      >
        {prompt === 'UNDER_MAINTENANCE' ? (
          <div className="flex flex-col px-6 tablet:px-12 py-10 pb-12 items-center">
            <h3
              className="text-river-black text-[20px] font-bold inline-flex 
            text-center items-center px-2 tablet:px-0 mobile:leading-normal uppercase"
            >
              General Maintenance Notification 🛠️
            </h3>

            <div className="mt-5 flex flex-col space-y-5 tablet:space-y-6 text-base">
              <p>
                The River Health Virtual Care Clinic is currently closed for general maintenance.
                Rest assured, we&apos;re working diligently to reopen the virtual clinic as soon as
                possible.
              </p>

              <p className="font-bold">
                If you have a medical emergency, please dial 911 or seek emergency care immediately.
              </p>

              <p>
                Have more questions? Visit our Support Center.
                <a
                  href={`${env('RIVER_APP_URL')}/support`}
                  className="underline block"
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${env('RIVER_APP_URL')}/support`.replace(/https?:\/\//, '')}
                </a>
              </p>
            </div>

            <hr className="w-full tablet:w-[55%] my-5 mx-auto" />

            <p className="text-center">Thanks for being a River Health member.</p>

            <div className="mt-8 tablet:mt-6">
              <Button label="Got It" onClick={onCloseModal} />
            </div>
          </div>
        ) : prompt === 'UNAVAILABLE' ? (
          <div className="flex flex-col justify-center items-center px-8 pb-10 pt-6 text-center">
            <img
              src="/assets/images/billing_error_min.gif"
              alt="loader status"
              width={150}
              height={150}
            />

            <p className="text-lg">
              Hello, your Primary Care Provider is currently reviewing your{' '}
              <strong className="underline underline-offset-1">{visit}</strong>. You can submit
              another online visit after your provider has responded to your current visit.
            </p>
          </div>
        ) : prompt === 'CLOSED' ? (
          <>
            <div className="bg-[#FFE0AF] rounded-t-xl">
              <Graphic
                id="graphicVirtualVisitsClosedInfoHeader"
                className="rounded-t-xl object-center object-cover
                w-full h-[120px] tablet:h-auto tablet:w-auto"
              />
            </div>
            <div className="flex flex-col p-8 pt-8 pb-10 items-center text-center">
              <h3
                className="text-river-black text-[20px] font-bold inline-flex 
              text-center items-center px-2 tablet:px-0 mobile:leading-normal uppercase"
              >
                Virtual Clinic Hours{'  '}
                <Icon id="iconSleepEmoji" className="ml-1 w-[24px]" />
              </h3>
              <MarkdownHTML
                className="mt-2"
                content={`Our virtual clinic is closed. You can submit a virtual visit to your River Health 
                provider during [clinic hours](https://helloriver.com/clinic-hours). 
                For medical emergencies, including mental health, call [911](tel:911) or 
                visit your nearest emergency department. For in-person care, 
                find a local in-network clinic [here](${paths.CLINIC_BOOKING_URL_PATH}).`}
              />
              <div className="mt-12 tablet:mt-8">
                <Button label="Got It" onClick={onCloseModal} className="" />
              </div>
            </div>
          </>
        ) : prompt === 'NOT-ACCESSIBLE' ? (
          <div className="flex flex-col px-6 tablet:px-12 py-10 pb-12 items-center">
            <h3
              className="text-river-black text-[20px] font-bold inline-flex 
            text-center items-center px-2 tablet:px-0 mobile:leading-normal uppercase"
            >
              Your River Health Subscription Plan Begins January 1, 2025
            </h3>

            <div className="mt-5 flex flex-col space-y-5 tablet:space-y-6 text-base">
              <p>Thanks for enrolling! You can begin using services on January 1, 2025.</p>

              <p className="font-bold">
                If you have a medical emergency, please dial 911 or seek emergency care immediately.
              </p>

              <p>
                Have questions? Contact our support team at
                <a
                  href="mailto:team@helloriver.com"
                  className="underline block text-river-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  team@helloriver.com
                </a>
              </p>
            </div>

            <hr className="w-full tablet:w-[55%] my-5 mx-auto" />

            <p className="text-center">Thanks for being a River Health member.</p>
          </div>
        ) : prompt === 'NO-PRESCRIPTION-CARD' ? (
          <div className="flex flex-col px-6 tablet:px-8 py-10 pb-11 items-center">
            <h3
              className="text-river-black text-[20px] font-bold inline-flex 
            text-center items-center px-2 tablet:px-0 mobile:leading-normal uppercase"
            >
              Prescription Cards Down
            </h3>

            <div className="mt-5 flex flex-col space-y-5 tablet:space-y-6 text-base">
              <p>
                Thanks for being a River Health member. Our Prescription Cards are currently down.
                We are working on resolving this as quickly as possible.
              </p>

              <p>
                Need to pick up medications?{' '}
                <span className="font-bold">
                  Use your River Health card to pay for formulary medication.
                </span>
              </p>

              <p className="font-bold">
                Don&apos;t have your River Health card? Follow the steps below:
              </p>

              <div className="!mt-2 flex flex-col space-y-3">
                {prescriptionInst.map((notice, index) => (
                  <div className="flex flex-row space-x-2" key={index}>
                    <span
                      className="bg-river-black rounded-full w-[25px] h-[25px] flex justify-center 
                      items-center text-white shrink-0 font-black text-[13px] mt-[5px]"
                    >
                      <Icon id="iconLongArrowRight" className="w-[15px] h-[15px]" />
                    </span>
                    <MarkdownHTML
                      className="text-sm text-left text-river-black px-1"
                      content={notice}
                    />
                  </div>
                ))}

                <div className="flex flex-row space-x-2">
                  <span
                    className="bg-river-black rounded-full w-[25px] h-[25px] justify-center 
                      items-center text-white shrink-0 font-black text-[13px] mt-[5px] hidden"
                  >
                    <Icon id="iconLongArrowRight" className="w-[15px] h-[15px]" />
                  </span>
                  <MarkdownHTML
                    className="text-sm text-left text-river-black px-1"
                    content="River Health offers formulary prescriptions only. View our full formulary at [helloriver.com/rx](https://helloriver.com/rx)"
                  />
                </div>
              </div>

              <p>
                Have questions? Visit the Support Center at
                <a
                  href={`${env('RIVER_APP_URL')}/support`}
                  className="underline block"
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${env('RIVER_APP_URL')}/support`.replace(/https?:\/\//, '')}
                </a>
              </p>
            </div>

            <hr className="w-full tablet:w-[55%] mt-6 mb-2 mx-auto" />

            <div className="mt-8 tablet:mt-6">
              <Button label="Got It" onClick={onCloseModal} />
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default VisitNoticePrompt;
