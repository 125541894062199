/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icons from '../../assets/icons/indexSVG';
import { ReactComponent as IconAdDoctorVisit } from '../../assets/icons/icon-ad-doctor-visits.svg';
import { ReactComponent as IconAdCheckups } from '../../assets/icons/icon-ad-checkups.svg';
import { ReactComponent as IconAdBirthControl } from '../../assets/icons/icon-ad-birth-control.svg';
import { ReactComponent as IconAdInfections } from '../../assets/icons/icon-ad-infections.svg';
import { ReactComponent as IconAdWellWoman } from '../../assets/icons/icon-ad-well-woman.svg';
import { ReactComponent as IconAdSkincare } from '../../assets/icons/icon-ad-skincare.svg';
import { ReactComponent as IconSuccessDiscountCode } from '../../assets/icons/icon-success-discount-code.svg';
import { ReactComponent as IconWelcomeEmoji } from '../../assets/icons/icon-welcome-emoji.svg';
import { ReactComponent as IconFeelingSickCareServices } from '../../assets/icons/icon-feeling-sick-care-services.svg';
import { ReactComponent as IconBirthControlCareServices } from '../../assets/icons/icon-birth-control-care-services.svg';
import { ReactComponent as IconUTITreatmentCareServices } from '../../assets/icons/icon-uti-treatment-care-services.svg';
import { ReactComponent as IconBehavioralHealthCareServices } from '../../assets/icons/icon-behavioral-health-care-services.svg';
import { ReactComponent as IconHairSkinCareServices } from '../../assets/icons/icon-hair-skin-care-services.svg';
import { ReactComponent as IconSTDTreatmentCareServices } from '../../assets/icons/icon-std-treatment-care-services.svg';
import { ReactComponent as IconAnnualPhysicalCareServices } from '../../assets/icons/icon-annual-physical-care-services.svg';
import { ReactComponent as IconMinorInjuriesCareServices } from '../../assets/icons/icon-minor-injuries-care-services.svg';
import { ReactComponent as IconWellWomanExamCareServices } from '../../assets/icons/icon-well-woman-exam-care-services.svg';
import { ReactComponent as IconMensIrregularitiesCareServices } from '../../assets/icons/icon-menstrual-irregularities-care-services.svg';
import { ReactComponent as IconVaginalInfectionsCareServices } from '../../assets/icons/icon-vaginal-infections-care-services.svg';
import { ReactComponent as IconErectileDysCareServices } from '../../assets/icons/icon-erectile-dysfunction-care-services.svg';
import { ReactComponent as IconSomethingElseCareServices } from '../../assets/icons/icon-something-else-care-services.svg';
import { ReactComponent as IconUserProfileMenu } from '../../assets/icons/icon_user_profile_menu.svg';
import { ReactComponent as IconUserProfileMenuWhatsNew } from '../../assets/icons/icon_user_profile_menu_whatsnew.svg';
import { ReactComponent as IconUserProfileMenuLogout } from '../../assets/icons/icon_user_profile_menu_logout.svg';
import { ReactComponent as IconUserPrivacyPolicy } from '../../assets/icons/icon_user_privacy_policy.svg';
import { ReactComponent as IconUserTermsOfService } from '../../assets/icons/icon_user_terms_of_service.svg';
import { ReactComponent as IconCareHistorySickVisit } from '../../assets/icons/icon_care_history_sick_visit.svg';
import { ReactComponent as IconCareHistoryBirthControlVisit } from '../../assets/icons/icon_care_history_birth_control_visit.svg';
import { ReactComponent as IconCareHistoryUTIVisit } from '../../assets/icons/icon_care_history_uti_visit.svg';
import { ReactComponent as IconCareHistorySTDDiagVisit } from '../../assets/icons/icon_care_history_std_diagnosis_visit.svg';
import { ReactComponent as IconCareHistorySTDTestVisit } from '../../assets/icons/icon_care_history_std_test_visit.svg';
import { ReactComponent as IconCareHistoryBehavioralHealthVisit } from '../../assets/icons/icon_care_history_beha_health_visit.svg';
import { ReactComponent as IconCareHistoryVaginalInfecVisit } from '../../assets/icons/icon_care_history_vag_infection_visit.svg';
import { ReactComponent as IconCareHistoryMenstraulVisit } from '../../assets/icons/icon_care_history_mens_irregularities_visit.svg';
import { ReactComponent as IconCareHistoryErectileDysVisit } from '../../assets/icons/icon_care_history_erectile_dys_visit.svg';
import { ReactComponent as IconCareHistoryHairSkinVisit } from '../../assets/icons/icon_care_history_hair_skin_visit.svg';
import { ReactComponent as IconMessageOffNotifyBadge } from '../../assets/icons/icon_message_off_notify_badge.svg';
import { ReactComponent as IconMessageOnNotifyBadge } from '../../assets/icons/icon_message_on_notify_badge.svg';
import { ReactComponent as IconWhatsNewHomepageFeature } from '../../assets/icons/icon_whatsnew_homepage_feature.svg';
import { ReactComponent as IconWhatsNewHairSkinFeature } from '../../assets/icons/icon_whatsnew_hair_skin_feature..svg';
import { ReactComponent as IconWhatsNewReproductiveFeature } from '../../assets/icons/icon_whatsnew_reproductive_care_feature.svg';
import { ReactComponent as IconProfileCameraUpload } from '../../assets/icons/icon_profile_camera_upload.svg';
import { ReactComponent as IconMessageTimestampAttached } from '../../assets/icons/icon_message_timestamp_attached.svg';
import { ReactComponent as IconCareHistoryLists } from '../../assets/icons/icon_care_history_lists.svg';
import { ReactComponent as IconChatPoint } from '../../assets/icons/icon_behavioral_upgrade_chat.svg';
import { ReactComponent as IconFreeShippingPoint } from '../../assets/icons/icon_behavioral_upgrade_free_shipping.svg';
import { ReactComponent as IconIDVerificationUpload } from '../../assets/icons/icon_id_verification_upload.svg';
import { ReactComponent as IconSelfieVerificationUpload } from '../../assets/icons/icon_selfie_verification_upload.svg';
import { ReactComponent as IconDoorstepDeliveryBox } from '../../assets/icons/icon_doorstep_delivery_box.svg';
import { ReactComponent as IconRefillPrescription } from '../../assets/icons/icon_refill_prescription.svg';
import { ReactComponent as IconClinicBuilding } from '../../assets/icons/icon_clinic_building.svg';
import { ReactComponent as IconEmailLabResult } from '../../assets/icons/icon_email_lab_result.svg';
import { ReactComponent as IconQuestLab } from '../../assets/icons/icon_quest_lab.svg';
import { ReactComponent as IconReviewLabReport } from '../../assets/icons/icon_review_lab_report.svg';
import { ReactComponent as IconInputFileUpload } from '../../assets/icons/icon_input_file_upload.svg';
import { ReactComponent as IconDiscreetMailBox } from '../../assets/icons/icon_discreet_mail_box.svg';
import { ReactComponent as IconReviewMessageFromTherapist } from '../../assets/icons/icon_review_message_from_therapist.svg';
import { ReactComponent as IconRefererProvider } from '../../assets/icons/icon_referer_provider.svg';
import { ReactComponent as IconTherapistInfoReview } from '../../assets/icons/icon_therapist_info_review.svg';
import { ReactComponent as IconProfileCodeChronic } from '../../assets/icons/icon_profile_code_chronic.svg';
import { ReactComponent as IconProfileCodeSexualHealth } from '../../assets/icons/icon_profile_code_sexual_health.svg';
import { ReactComponent as IconProfileCodeCovid } from '../../assets/icons/icon_profile_code_covid.svg';
import { ReactComponent as IconProfileCodeConditions } from '../../assets/icons/icon_profile_code_conditions.svg';
import { ReactComponent as IconPresBirthControl } from '../../assets/icons/icon_prescription_birth_control.svg';
import { ReactComponent as IconPresCost } from '../../assets/icons/icon_prescription_cost.svg';
import { ReactComponent as IconPresDelivery } from '../../assets/icons/icon_prescription_delivery.svg';
import { ReactComponent as IconPresRefills } from '../../assets/icons/icon_prescription_refills.svg';
import { ReactComponent as IconSubStar } from '../../assets/icons/icon_subscription_type_premium_tag.svg';
import { ReactComponent as IconSubHeadsEmoji } from '../../assets/icons/icon_subscription_heads_emoji.svg';
import { ReactComponent as IconMedRecordsConcierge } from '../../assets/icons/icon_medical_records_concierge.svg';
import { ReactComponent as IconMedRecordsEnroll } from '../../assets/icons/icon_medical_records_enroll.svg';
import { ReactComponent as IconMedRecordsFamilyPlan } from '../../assets/icons/icon_medical_records_family_plan.svg';
import { ReactComponent as IconMedRecordsInsurance } from '../../assets/icons/icon_medical_records_insurance.svg';
import { ReactComponent as IconEmployerCodeBehavioral } from '../../assets/icons/icon_employer_code_behavioral.svg';
import { ReactComponent as IconEmployerCodeLabs } from '../../assets/icons/icon_employer_code_labs.svg';
import { ReactComponent as IconEmployerCodeOfficeVisits } from '../../assets/icons/icon_employer_code_office_visits.svg';
import { ReactComponent as IconEmployerCodePrescriptions } from '../../assets/icons/icon_employer_code_prescriptions.svg';
import { ReactComponent as IconEmployerCodeUnlimitedVirtualCare } from '../../assets/icons/icon_employer_code_unlimited_virtual_care.svg';
import { ReactComponent as IconEmployerCodeWellness } from '../../assets/icons/icon_employer_code_wellness.svg';
import { ReactComponent as IconChatCardPrescription } from '../../assets/icons/icon_chat_card_prescription.svg';
import { ReactComponent as IconChatCardVisitPrescription } from '../../assets/icons/icon_chat_card_visit.svg';
import { ReactComponent as IconChatCardBirthControlPill } from '../../assets/icons/icon_chat_card_birth_control_pill.svg';
import { ReactComponent as IconChatCardSickVisitPill } from '../../assets/icons/icon_chat_card_sick_visit_pill.svg';
import { ReactComponent as IconChatCardUTI } from '../../assets/icons/icon_chat_card_uti.svg';
import { ReactComponent as IconChatCardMentalHealthTreatment } from '../../assets/icons/icon_chat_card_mental_health_treatment.svg';
import { ReactComponent as IconChatCardSTDDiagnosis } from '../../assets/icons/icon_chat_card_std_diagnosis.svg';
import { ReactComponent as IconChatCardSTDTest } from '../../assets/icons/icon_chat_card_std_test.svg';
import { ReactComponent as IconChatCardMenstrualIrregularities } from '../../assets/icons/icon_chat_card_menstrual_irregularities.svg';
import { ReactComponent as IconChatCardVaginalInfections } from '../../assets/icons/icon_chat_card_vaginal_infections.svg';
import { ReactComponent as IconChatCardErectileDysfunction } from '../../assets/icons/icon_chat_card_erectile_dysfunction.svg';
import { ReactComponent as IconChatCardHairSkin } from '../../assets/icons/icon_chat_card_hair_skin.svg';
import { ReactComponent as IconChatPreviewDocument } from '../../assets/icons/icon_chat_preview_document.svg';
import { ReactComponent as IconChatPreviewImage } from '../../assets/icons/icon_chat_preview_image.svg';
import { ReactComponent as IconChatPreviewSticker } from '../../assets/icons/icon_chat_preview_sticker.svg';
import { ReactComponent as IconChatPreviewVideo } from '../../assets/icons/icon_chat_preview_video.svg';
import { ReactComponent as IconChatPreviewWarning } from '../../assets/icons/icon_chat_preview_warning.svg';
import { ReactComponent as IconChatDocumentUpload } from '../../assets/icons/icon_chat_document_upload.svg';
import { ReactComponent as IconChatDocumentPreviewDownload } from '../../assets/icons/icon_chat_document_download.svg';
import { ReactComponent as IconChatCardVideoCall } from '../../assets/icons/icon_chat_card_video_call.svg';
import { ReactComponent as IconSleepEmoji } from '../../assets/icons/icon_sleep_emoji.svg';
import { ReactComponent as IconLongArrowRight } from '../../assets/icons/icon_long_arrow_right.svg';
import { ReactComponent as IconChatCardRxRefill } from '../../assets/icons/icon_chat_card_rx_refill.svg';
import { ReactComponent as IconChatCardSexualHealth } from '../../assets/icons/icon_chat_card_sexual_health.svg';
import { ReactComponent as IconExpandAndView } from '../../assets/icons/icon_expand_and_view.svg';
import { ReactComponent as IconCareNotesAttachment } from '../../assets/icons/icon_care_notes_attachment.svg';
import { ReactComponent as IconHealthReportDocument } from '../../assets/icons/icon_health_report_document.svg';
import { ReactComponent as IconArrivalScheduling } from '../../assets/icons/icon-arrival-scheduling.svg';
import { ReactComponent as IconKiosk } from '../../assets/icons/icon-kiosk.svg';
import { ReactComponent as IconAddFamily } from '../../assets/icons/icon_user_profile_menu_add_family.svg';
import { ReactComponent as IconAppointInfoOne } from '../../assets/icons/icon_appointment_info_one.svg';
import { ReactComponent as IconAppointInfoTwo } from '../../assets/icons/icon_appointment_info_two.svg';
import { ReactComponent as IconAppointInfoThree } from '../../assets/icons/icon_appointment_info_three.svg';
import { ReactComponent as IconAppointInfoFour } from '../../assets/icons/icon_appointment_info_four.svg';
import { ReactComponent as IconHintInfo } from '../../assets/icons/icon_hint_info.svg';
import { ReactComponent as IconApptClinicsPlaceholder } from '../../assets/icons/icon_appointment_clinics_placeholder.svg';
import { ReactComponent as IconApptProviderPlaceholder } from '../../assets/icons/icon_appointment_provider_placeholder.svg';
import { ReactComponent as IconAlertDanger } from '../../assets/icons/icon_alert_danger.svg';
import { ReactComponent as IconCardActivate } from '../../assets/icons/icon_user_profile_menu_card_activation.svg';
import { ReactComponent as IconReimbursement } from '../../assets/icons/icon_user_profile_menu_reimbursement.svg';
import { ReactComponent as IconUpgradePlan } from '../../assets/icons/icon_user_profile_menu_upgrade.svg';
import { ReactComponent as IconPresIdCard } from '../../assets/icons/icon_user_profile_menu_id_card.svg';
import { ReactComponent as IconEmergencyCall } from '../../assets/icons/icon_emergency_call.svg';
import { ReactComponent as IconMail } from '../../assets/icons/icon_mail.svg';
import { ReactComponent as IconIdPhoto } from '../../assets/icons/icon_id_photo.svg';
import { ReactComponent as IconSelfiePhoto } from '../../assets/icons/icon_selfie_photo.svg';
import { ReactComponent as IconDoctorVirtualCare } from '../../assets/icons/icon_doctor_virtual_care.svg';
import { ReactComponent as IconVisaLogo } from '../../assets/icons/icon_visa_logo.svg';
import { ReactComponent as IconClinicServiceBookAppt } from '../../assets/icons/icon_clinic_service_book_appointment.svg';
import { ReactComponent as IconThumbsUp } from '../../assets/icons/icon_thumbs_up.svg';
import { ReactComponent as IconInsuranceBenefit } from '../../assets/icons/icon_user_profile_menu_insurance_benefit.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/icon_search.svg';

type IconProps = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const allIcons: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any> = {
  ...Icons,
  adDoctorVisit: IconAdDoctorVisit,
  adCheckups: IconAdCheckups,
  adBirthControl: IconAdBirthControl,
  adInfections: IconAdInfections,
  adWellWoman: IconAdWellWoman,
  adSkincare: IconAdSkincare,
  successDiscountCode: IconSuccessDiscountCode,
  iconWelcomeEmoji: IconWelcomeEmoji,
  iconFeelingSickCareServices: IconFeelingSickCareServices,
  iconBirthControlCareServices: IconBirthControlCareServices,
  iconUTITreatmentCareServices: IconUTITreatmentCareServices,
  iconBehavioralHealthCareServices: IconBehavioralHealthCareServices,
  iconSTDTreatmentCareServices: IconSTDTreatmentCareServices,
  iconHairSkinCareServices: IconHairSkinCareServices,
  iconAnnualPhysicalCareServices: IconAnnualPhysicalCareServices,
  iconMinorInjuriesCareServices: IconMinorInjuriesCareServices,
  iconWellWomanExamCareServices: IconWellWomanExamCareServices,
  iconMensIrregularitiesCareServices: IconMensIrregularitiesCareServices,
  iconVaginalInfectionsCareServices: IconVaginalInfectionsCareServices,
  iconErectileDysCareServices: IconErectileDysCareServices,
  iconSomethingElseCareServices: IconSomethingElseCareServices,
  iconUserProfileMenu: IconUserProfileMenu,
  iconUserProfileMenuWhatsNew: IconUserProfileMenuWhatsNew,
  iconUserProfileMenuLogout: IconUserProfileMenuLogout,
  iconUserTermsOfService: IconUserTermsOfService,
  iconUserPrivacyPolicy: IconUserPrivacyPolicy,
  iconCareHistorySickVisit: IconCareHistorySickVisit,
  iconCareHistoryBirthControlVisit: IconCareHistoryBirthControlVisit,
  iconCareHistoryUTIVisit: IconCareHistoryUTIVisit,
  iconCareHistorySTDDiagVisit: IconCareHistorySTDDiagVisit,
  iconCareHistorySTDTestVisit: IconCareHistorySTDTestVisit,
  iconCareHistoryBehavioralHealthVisit: IconCareHistoryBehavioralHealthVisit,
  iconCareHistoryVaginalInfecVisit: IconCareHistoryVaginalInfecVisit,
  iconCareHistoryMenstraulVisit: IconCareHistoryMenstraulVisit,
  iconCareHistoryErectileDysVisit: IconCareHistoryErectileDysVisit,
  iconCareHistoryHairSkinVisit: IconCareHistoryHairSkinVisit,
  iconMessageOffNotifyBadge: IconMessageOffNotifyBadge,
  iconMessageOnNotifyBadge: IconMessageOnNotifyBadge,
  iconWhatsNewHomepageFeature: IconWhatsNewHomepageFeature,
  iconWhatsNewHairSkinFeature: IconWhatsNewHairSkinFeature,
  iconWhatsNewReproductiveFeature: IconWhatsNewReproductiveFeature,
  iconProfileCameraUpload: IconProfileCameraUpload,
  iconMessageTimestampAttached: IconMessageTimestampAttached,
  iconCareHistoryList: IconCareHistoryLists,
  iconChatPoint: IconChatPoint,
  iconFreeShippingPoint: IconFreeShippingPoint,
  iconIDVerificationUpload: IconIDVerificationUpload,
  iconSelfieVerificationUpload: IconSelfieVerificationUpload,
  iconDoorstepDeliveryBox: IconDoorstepDeliveryBox,
  iconRefillPrescription: IconRefillPrescription,
  iconClinicBuilding: IconClinicBuilding,
  iconEmailLabResult: IconEmailLabResult,
  iconQuestLab: IconQuestLab,
  iconReviewLabReport: IconReviewLabReport,
  iconInputFileUpload: IconInputFileUpload,
  iconDiscreetMailBox: IconDiscreetMailBox,
  iconReviewMessageFromTherapist: IconReviewMessageFromTherapist,
  iconRefererProvider: IconRefererProvider,
  iconTherapistInfoReview: IconTherapistInfoReview,
  iconProfileCodeChronic: IconProfileCodeChronic,
  iconProfileCodeSexualHealth: IconProfileCodeSexualHealth,
  iconProfileCodeCovid: IconProfileCodeCovid,
  iconProfileCodeConditions: IconProfileCodeConditions,
  iconPresBirthControl: IconPresBirthControl,
  iconPresCost: IconPresCost,
  iconPresDelivery: IconPresDelivery,
  iconPresRefills: IconPresRefills,
  iconSubPremiumStar: IconSubStar,
  iconSubHeadsEmoji: IconSubHeadsEmoji,
  iconMedRecordsConcierge: IconMedRecordsConcierge,
  iconMedRecordsEnroll: IconMedRecordsEnroll,
  iconMedRecordsFamilyPlan: IconMedRecordsFamilyPlan,
  iconMedRecordsInsurance: IconMedRecordsInsurance,
  iconEmployerCodeBehavioral: IconEmployerCodeBehavioral,
  iconEmployerCodeLabs: IconEmployerCodeLabs,
  iconEmployerCodeOfficeVisits: IconEmployerCodeOfficeVisits,
  iconEmployerCodePrescriptions: IconEmployerCodePrescriptions,
  iconEmployerCodeUnlimitedVirtualCare: IconEmployerCodeUnlimitedVirtualCare,
  iconEmployerCodeWellness: IconEmployerCodeWellness,
  iconChatCardPrescription: IconChatCardPrescription,
  iconChatCardVisitPrescription: IconChatCardVisitPrescription,
  iconChatCardBirthControlPill: IconChatCardBirthControlPill,
  iconChatCardSickVisitPill: IconChatCardSickVisitPill,
  iconChatCardUTI: IconChatCardUTI,
  iconChatCardMentalHealthTreatment: IconChatCardMentalHealthTreatment,
  iconChatCardSTDDiagnosis: IconChatCardSTDDiagnosis,
  iconChatCardSTDTest: IconChatCardSTDTest,
  iconChatCardMenstrualIrregularities: IconChatCardMenstrualIrregularities,
  iconChatCardVaginalInfections: IconChatCardVaginalInfections,
  iconChatCardErectileDysfunction: IconChatCardErectileDysfunction,
  iconChatCardHairSkin: IconChatCardHairSkin,
  iconChatPreviewDocument: IconChatPreviewDocument,
  iconChatPreviewImage: IconChatPreviewImage,
  iconChatPreviewSticker: IconChatPreviewSticker,
  iconChatPreviewVideo: IconChatPreviewVideo,
  iconChatPreviewWarning: IconChatPreviewWarning,
  iconChatDocumentUpload: IconChatDocumentUpload,
  iconChatDocumentPreviewDownload: IconChatDocumentPreviewDownload,
  iconChatCardVideoCall: IconChatCardVideoCall,
  iconSleepEmoji: IconSleepEmoji,
  iconLongArrowRight: IconLongArrowRight,
  iconChatCardRxRefill: IconChatCardRxRefill,
  iconChatCardSexualHealth: IconChatCardSexualHealth,
  iconExpandAndView: IconExpandAndView,
  iconCareNotesAttachment: IconCareNotesAttachment,
  iconHealthReportDocument: IconHealthReportDocument,
  iconArrivalScheduling: IconArrivalScheduling,
  iconKiosk: IconKiosk,
  iconAddFamily: IconAddFamily,
  iconAppointInfoOne: IconAppointInfoOne,
  iconAppointInfoTwo: IconAppointInfoTwo,
  iconAppointInfoThree: IconAppointInfoThree,
  iconAppointInfoFour: IconAppointInfoFour,
  iconHintInfo: IconHintInfo,
  iconApptClinicsPlaceholder: IconApptClinicsPlaceholder,
  iconApptProviderPlaceholder: IconApptProviderPlaceholder,
  iconAlertDanger: IconAlertDanger,
  iconCardActivate: IconCardActivate,
  iconReimbursement: IconReimbursement,
  iconUpgradePlan: IconUpgradePlan,
  iconPresIdCard: IconPresIdCard,
  iconEmergencyCall: IconEmergencyCall,
  iconMail: IconMail,
  iconIdPhoto: IconIdPhoto,
  iconSelfiePhoto: IconSelfiePhoto,
  iconDoctorVirtualCare: IconDoctorVirtualCare,
  iconVisaLogo: IconVisaLogo,
  iconClinicServiceBookAppt: IconClinicServiceBookAppt,
  iconThumbsUp: IconThumbsUp,
  iconInsuranceBenefit: IconInsuranceBenefit,
  iconSearch: IconSearch,
};

function Icon({ id, ...rest }: IconProps) {
  const SelectedIcon = allIcons[id];
  return SelectedIcon ? <SelectedIcon id={id} {...rest} /> : null;
}

export function IconSprite({ id, ...rest }: IconProps) {
  return (
    <svg {...rest}>
      <use href={`/assets/icons/sprite.svg#${id}`} />
    </svg>
  );
}

Icon.defaultProps = {
  className: '',
  style: undefined,
  onClick: () => false,
};

export default Icon;
