const envVars = process.env;

const env = (value: string) => {
  const key = `REACT_APP_${value}`;

  if (!(key in envVars)) {
    throw new Error(`no env config found for ${value}`);
  }

  return envVars[key];
};

export default env;
