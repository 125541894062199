import React, { ChangeEvent, FocusEvent } from 'react';
import { Input } from '../..';

type DateInputProps = {
  className?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
};

function DateInput({
  className,
  name,
  type,
  disabled,
  error,
  onBlur,
  onFocus,
  placeholder,
  value,
  onChange,
}: DateInputProps) {
  return (
    <div className={className || ''}>
      {type === 'date' ? (
        <Input
          className="date-input-date"
          type="text"
          value={value}
          name={name}
          placeholder="mm/dd/yyyy"
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
          maxLength={10}
        />
      ) : (
        <Input
          className="date-input-text"
          type="text"
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={() => false}
          onFocus={onFocus}
          error={error}
        />
      )}
    </div>
  );
}

DateInput.defaultProps = {
  className: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  error: false,
  onBlur: undefined,
  onFocus: undefined,
};

export default DateInput;
