import * as React from 'react';
import { isEmpty } from 'ramda';
import { CheckBox, Input } from '../..';

type Answer = {
  questionKey: string;
  answers: Array<string>;
};

type CustomBloodPressureInputBuilderProps = {
  id: string;
  label: string;
  value: string;
  error?: string;
  notice?: string;
  onInputChange: (answers: Answer) => void;
};

function CustomBloodPressureInputBuilder({
  id,
  label,
  value,
  error,
  notice,
  onInputChange,
}: CustomBloodPressureInputBuilderProps) {
  const [confirmInfo, setConfirmInfo] = React.useState(false);
  const [bloodPressureValues, setBloodPressureValues] = React.useState(() => {
    return {
      systolic: value ? value.split(' ')[0].replace(/[^0-9]/g, '') : '',
      diastolic: value ? value.split(' ')[1].replace(/[^0-9]/g, '') : '',
    };
  });

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value: elValue, name } = event.target;

    if (!elValue.match('^[0-9]*$')) {
      return;
    }

    setBloodPressureValues(values => {
      return { ...values, [name]: elValue };
    });

    const changedValues = Object.values({ ...bloodPressureValues, [name]: elValue });

    if (changedValues && changedValues.every(fndChangeValue => !isEmpty(fndChangeValue))) {
      const systolic = parseInt(changedValues[0], 10);
      const diastolic = parseInt(changedValues[1], 10);

      const bloodPressure = `${systolic}/${diastolic}`;

      onInputChange({ questionKey: id, answers: [bloodPressure] });
    } else {
      onInputChange({ questionKey: id, answers: [''] });
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-3">
        <label htmlFor={id} className="text-lg font-normal leading-8">
          {label}
        </label>
        {notice && <span className="text-xs text-[#FF9B5B] mt-1 font-medium">{notice}</span>}
      </div>
      <div className="flex flex-row space-x-5 items-center">
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0
          focus:border-b-[1.5px] focus:ring-0 text-center"
          name="systolic"
          value={bloodPressureValues.systolic}
          onChange={handleInputsChange}
          error={Boolean(error)}
          maxLength={3}
        />
        <span className="block text-center font-medium">/</span>
        <Input
          className="px-1 py-1 border-[#303030] border-0 border-b-[1.2px] rounded-none focus:border-0 
          focus:border-b-[1.5px] focus:ring-0 text-center"
          name="diastolic"
          value={bloodPressureValues.diastolic}
          onChange={handleInputsChange}
          error={Boolean(error)}
          maxLength={3}
        />
      </div>
      {error ? (
        <span className="text-river-red text-left text-xs mt-2 inline-flex">{error}</span>
      ) : (
        <span className="text-[#C4C4C4] text-left text-xs mt-2 inline-flex">Example: 120/80</span>
      )}

      <div className="mt-5">
        <label
          htmlFor="confirm"
          className="text-[16px] block pl-[18px] -indent-[18px] cursor-pointer"
        >
          <CheckBox
            id="confirm"
            name="confirm"
            value="false"
            checked={confirmInfo}
            onChange={event => setConfirmInfo(event.target.checked)}
            className="align-middle cursor-pointer"
          />{' '}
          <span className="align-middle">I don’t know my blood pressure</span>
        </label>

        {confirmInfo && (
          <div className="text-[15px] border border-river-blue bg-[#EEFBFF] rounded-md px-3 py-2 mt-3">
            That’s okay. You can visit your local pharmacy to get your latest blood pressure
            reading. We need your blood pressure to prescribe the most effective birth control.
          </div>
        )}
      </div>
    </div>
  );
}

CustomBloodPressureInputBuilder.defaultProps = {
  notice: undefined,
  error: undefined,
};

export default CustomBloodPressureInputBuilder;
